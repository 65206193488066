import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import ReactConfetti from 'react-confetti';
import { Badge } from './ui/badge';
import Button from './ui/button';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from './ui/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from './ui/tabs';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from './ui/dialog';
import { useToast } from './ui/toast';
import { Toaster } from './ui/toast';
import { logUserActivity } from '../utils/activityTracker'; // Added import for activity logging

// Define the Competition interface
interface Competition {
  id: string;
  title: string;
  status: 'entered' | 'ignored' | 'open';
  won: boolean;
  closing_date: string;
  created_at: string;
}

// StatusIcon component to display different icons based on competition status
const StatusIcon = ({
  status,
  won,
}: {
  status: Competition['status'];
  won: boolean;
}) => {
  const iconColor = won
    ? 'text-[#22C55E]'
    : status === 'entered'
    ? 'text-[#3B82F6]'
    : status === 'ignored'
    ? 'text-[#EF4444]'
    : 'text-[#9333EA]';

  if (won)
    return (
      <svg
        className={`w-6 h-6 sm:w-6 sm:h-6 ${iconColor}`}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.21 13.89L7 23L12 20L17 23L15.79 13.88"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 15C15.866 15 19 11.866 19 8V1H5V8C5 11.866 8.13401 15 12 15Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );

  switch (status) {
    case 'entered':
      return (
        <svg
          className={`w-6 h-6 sm:w-6 sm:h-6 ${iconColor}`}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20 12V22H4V12"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22 7H2V12H22V7Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 22V7"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 7H16.5C17.163 7 17.7989 6.73661 18.2678 6.26777C18.7366 5.79893 19 5.16304 19 4.5C19 3.83696 18.7366 3.20107 18.2678 2.73223C17.7989 2.26339 17.163 2 16.5 2C13 2 12 7 12 7Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 7H7.5C6.83696 7 6.20107 6.73661 5.73223 6.26777C5.26339 5.79893 5 5.16304 5 4.5C5 3.83696 5.26339 3.20107 5.73223 2.73223C6.20107 2.26339 6.83696 2 7.5 2C11 2 12 7 12 7Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'ignored':
      return (
        <svg
          className={`w-6 h-6 sm:w-6 sm:h-6 ${iconColor}`}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15 9L9 15"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9 9L15 15"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    default:
      return (
        <svg
          className={`w-6 h-6 sm:w-6 sm:h-6 ${iconColor}`}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 6V12L16 14"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
  }
};

// Confirmation Dialog Component
const ConfirmationDialog = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  description,
}: {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  description: string;
}) => (
  <Dialog open={isOpen} onOpenChange={onClose}>
    <DialogContent>
      <DialogHeader>
        <DialogTitle>{title}</DialogTitle>
        <DialogDescription>{description}</DialogDescription>
      </DialogHeader>
      <DialogFooter>
        <Button variant="outline" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={onConfirm}>Confirm</Button>
      </DialogFooter>
    </DialogContent>
  </Dialog>
);

// Main Competitions component
const Competitions: React.FC = () => {
  const [competitions, setCompetitions] = useState<Competition[]>([]);
  const [showConfetti, setShowConfetti] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [confirmation, setConfirmation] = useState<{
    isOpen: boolean;
    competitionId: string;
    newStatus: Competition['status'];
    won: boolean;
  }>({
    isOpen: false,
    competitionId: '',
    newStatus: 'open',
    won: false,
  });

  const { toast } = useToast();

  // Fetch competitions on component mount
  useEffect(() => {
    fetchCompetitions();
  }, []);

  // Function to fetch competitions from Supabase
  const fetchCompetitions = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const {
        data: { user },
        error: authError,
      } = await supabase.auth.getUser();
      if (authError || !user) {
        setError('User not authenticated');
        setIsLoading(false);
        return;
      }

      const { data, error } = await supabase
        .from('user_competition_status')
        .select(
          `
            competition_id,
            status,
            won,
            competitions!user_competition_status_competition_id_fkey (
              id,
              title,
              closing_date,
              created_at
            )
          `
        )
        .eq('user_id', user.id);

      if (error) {
        console.error('Error fetching competitions:', error);
        setError('Failed to fetch competitions');
      } else if (data) {
        const formattedData: Competition[] = data.reduce(
          (acc: Competition[], item: any) => {
            if (
              item.competitions &&
              typeof item.competitions === 'object' &&
              item.competitions.id
            ) {
              acc.push({
                id: item.competitions.id,
                title: item.competitions.title,
                status: item.status || 'open',
                won: item.won,
                closing_date: item.competitions.closing_date,
                created_at: item.competitions.created_at,
              });
            }
            return acc;
          },
          []
        );
        setCompetitions(formattedData);
      }
    } catch (err) {
      console.error('Unexpected error:', err);
      setError('An unexpected error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  // Function to handle status update with confirmation
  const handleUpdateStatus = (
    competitionId: string,
    newStatus: Competition['status'],
    won: boolean
  ) => {
    setConfirmation({
      isOpen: true,
      competitionId,
      newStatus,
      won,
    });
  };

  // Function to confirm status update
  const confirmUpdateStatus = async () => {
    const { competitionId, newStatus, won } = confirmation;
    setConfirmation({ ...confirmation, isOpen: false });
    try {
      const {
        data: { user },
        error: authError,
      } = await supabase.auth.getUser();
      if (authError || !user) {
        addToast('error', 'User not authenticated');
        return;
      }

      // Retrieve username from user metadata
      const username: string = user.user_metadata?.username || 'Unknown User';

      const { error } = await supabase
        .from('user_competition_status')
        .update({
          status: newStatus,
          won: won,
        })
        .eq('user_id', user.id)
        .eq('competition_id', competitionId);

      if (error) {
        console.error('Error updating competition status:', error);
        addToast('error', 'Failed to update competition status. Please try again.');
      } else {
        setCompetitions((prevCompetitions) =>
          prevCompetitions.map((comp) =>
            comp.id === competitionId ? { ...comp, status: newStatus, won: won } : comp
          )
        );
        if (won) {
          setShowConfetti(true);
          setTimeout(() => setShowConfetti(false), 5000);
        }
        addToast(
          'success',
          `Competition ${
            won ? 'marked as won' : `status updated to ${capitalizeFirstLetter(newStatus)}`
          }.`
        );

        // Log user activity based on the new status
        if (newStatus === 'entered') {
          await logUserActivity(user.id, username, {
            action_type: 'competition_entered',
            details: { competition_id: competitionId },
          });
        } else if (newStatus === 'ignored') {
          await logUserActivity(user.id, username, {
            action_type: 'competition_ignored',
            details: { competition_id: competitionId },
          });
        }
      }
    } catch (err) {
      console.error('Unexpected error during status update:', err);
      addToast('error', 'An unexpected error occurred. Please try again.');
    }
  };

  // Helper function to add toast
  const addToast = (type: 'success' | 'error', message: string) => {
    toast({
      variant: type === 'error' ? 'destructive' : 'default',
      title: type === 'error' ? 'Error' : 'Success',
      description: message,
    });
  };

  // Calculate statistics
  const stats = {
    total: competitions.length,
    entered: competitions.filter((c) => c.status === 'entered' && !c.won).length,
    won: competitions.filter((c) => c.won).length,
    ignored: competitions.filter((c) => c.status === 'ignored').length,
  };

  // Loading state with spinner
  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-purple-500"></div>
      </div>
    );
  }

  // Error state
  if (error) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-red-500 text-lg">{error}</div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-2 sm:px-4 lg:px-8 py-8 bg-[#EEF0FD] min-h-screen text-[#111827]">
      {showConfetti && <ReactConfetti />}
      <h1 className="text-2xl sm:text-3xl font-bold mb-6">My Competitions</h1>

      {/* Confirmation Dialog */}
      <ConfirmationDialog
        isOpen={confirmation.isOpen}
        onClose={() =>
          setConfirmation({ ...confirmation, isOpen: false })
        }
        onConfirm={confirmUpdateStatus}
        title="Confirm Status Update"
        description={`Are you sure you want to mark this competition as ${
          confirmation.won
            ? 'won'
            : capitalizeFirstLetter(confirmation.newStatus)
        }?`}
      />

      {/* Statistics Grid */}
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 mb-8">
        <StatCard
          title="Total Competitions"
          value={stats.total}
          color="text-[#9333EA]"
          icon={
            <svg
              className="w-6 h-6 sm:w-8 sm:h-8"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 21V5C19 4.46957 18.7893 3.96086 18.4142 3.58579C18.0391 3.21071 17.5304 3 17 3H7C6.46957 3 5.96086 3.21071 5.58579 3.58579C5.21071 3.96086 5 4.46957 5 5V21L12 17.5L19 21Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          }
        />
        <StatCard
          title="Entered"
          value={stats.entered}
          color="text-[#3B82F6]"
          icon={
            <svg
              className="w-6 h-6 sm:w-8 sm:h-8"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 12V22H4V12"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M22 7H2V12H22V7Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 22V7"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 7H16.5C17.163 7 17.7989 6.73661 18.2678 6.26777C18.7366 5.79893 19 5.16304 19 4.5C19 3.83696 18.7366 3.20107 18.2678 2.73223C17.7989 2.26339 17.163 2 16.5 2C13 2 12 7 12 7Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 7H7.5C6.83696 7 6.20107 6.73661 5.73223 6.26777C5.26339 5.79893 5 5.16304 5 4.5C5 3.83696 5.26339 3.20107 5.73223 2.73223C6.20107 2.26339 6.83696 2 7.5 2C11 2 12 7 12 7Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          }
        />
        <StatCard
          title="Won"
          value={stats.won}
          color="text-[#22C55E]"
          icon={
            <svg
              className="w-6 h-6 sm:w-8 sm:h-8"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.21 13.89L7 23L12 20L17 23L15.79 13.88"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 15C15.866 15 19 11.866 19 8V1H5V8C5 11.866 8.13401 15 12 15Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          }
        />
        <StatCard
          title="Ignored"
          value={stats.ignored}
          color="text-[#EF4444]"
          icon={
            <svg
              className="w-6 h-6 sm:w-8 sm:h-8"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M15 9L9 15"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9 9L15 15"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          }
        />
      </div>

      {/* Tabs for Competition Lists */}
      <Tabs defaultValue="all" className="space-y-4">
        <TabsList className="mb-4 flex flex-wrap gap-2 rounded-md">
          <TabsTrigger
            value="all"
            className="flex-shrink-0 data-[state=active]:bg-[#9333EA] data-[state=active]:text-white px-3 sm:px-4 py-1.5 sm:py-2 rounded-full whitespace-nowrap"
          >
            All
          </TabsTrigger>
          <TabsTrigger
            value="entered"
            className="flex-shrink-0 data-[state=active]:bg-[#9333EA] data-[state=active]:text-white px-3 sm:px-4 py-1.5 sm:py-2 rounded-full whitespace-nowrap"
          >
            Entered
          </TabsTrigger>
          <TabsTrigger
            value="won"
            className="flex-shrink-0 data-[state=active]:bg-[#9333EA] data-[state=active]:text-white px-3 sm:px-4 py-1.5 sm:py-2 rounded-full whitespace-nowrap"
          >
            Won
          </TabsTrigger>
          <TabsTrigger
            value="ignored"
            className="flex-shrink-0 data-[state=active]:bg-[#9333EA] data-[state=active]:text-white px-3 sm:px-4 py-1.5 sm:py-2 rounded-full whitespace-nowrap"
          >
            Ignored
          </TabsTrigger>
        </TabsList>

        {/* All Competitions */}
        <TabsContent value="all" className="space-y-4">
          {competitions.length === 0 ? (
            <div className="text-center text-gray-500">No competitions found.</div>
          ) : (
            competitions.map((comp) => (
              <CompetitionCard
                key={comp.id}
                competition={comp}
                onUpdateStatus={handleUpdateStatus}
              />
            ))
          )}
        </TabsContent>

        {/* Entered Competitions */}
        <TabsContent value="entered" className="space-y-4">
          {competitions.filter((c) => c.status === 'entered' && !c.won).length === 0 ? (
            <div className="text-center text-gray-500">No entered competitions.</div>
          ) : (
            competitions
              .filter((c) => c.status === 'entered' && !c.won)
              .map((comp) => (
                <CompetitionCard
                  key={comp.id}
                  competition={comp}
                  onUpdateStatus={handleUpdateStatus}
                />
              ))
          )}
        </TabsContent>

        {/* Won Competitions */}
        <TabsContent value="won" className="space-y-4">
          {competitions.filter((c) => c.won).length === 0 ? (
            <div className="text-center text-gray-500">No won competitions.</div>
          ) : (
            competitions
              .filter((c) => c.won)
              .map((comp) => (
                <CompetitionCard
                  key={comp.id}
                  competition={comp}
                  onUpdateStatus={handleUpdateStatus}
                />
              ))
          )}
        </TabsContent>

        {/* Ignored Competitions */}
        <TabsContent value="ignored" className="space-y-4">
          {competitions.filter((c) => c.status === 'ignored').length === 0 ? (
            <div className="text-center text-gray-500">No ignored competitions.</div>
          ) : (
            competitions
              .filter((c) => c.status === 'ignored')
              .map((comp) => (
                <CompetitionCard
                  key={comp.id}
                  competition={comp}
                  onUpdateStatus={handleUpdateStatus}
                />
              ))
          )}
        </TabsContent>
      </Tabs>
      <Toaster />
    </div>
  );
};

// StatCard component to display individual statistics
function StatCard({
  title,
  value,
  color,
  icon,
}: {
  title: string;
  value: number;
  color: string;
  icon: React.ReactNode;
}) {
  return (
    <Card className="bg-white">
      <CardContent className="p-4">
        <div className="flex items-center space-x-3 mb-2">
          <div className={`p-2 rounded-full ${color} bg-opacity-10`}>{icon}</div>
          <div className={`text-lg font-bold ${color}`}>{value}</div>
        </div>
        <div className="text-xs font-medium text-[#111827]">{title}</div>
      </CardContent>
    </Card>
  );
}

// CompetitionCard component to display individual competitions
function CompetitionCard({
  competition,
  onUpdateStatus,
}: {
  competition: Competition;
  onUpdateStatus: (
    id: string,
    status: Competition['status'],
    won: boolean
  ) => void;
}) {
  const isOpen = competition.status === 'open';
  const isEntered = competition.status === 'entered' && !competition.won;
  const isWon = competition.won;
  const isIgnored = competition.status === 'ignored';

  return (
    <Card className="bg-white overflow-hidden shadow rounded-lg">
      <div className="flex items-center p-4 flex-col sm:flex-row">
        <div className="flex-shrink-0 mr-0 sm:mr-4 mb-2 sm:mb-0">
          <StatusIcon status={competition.status} won={competition.won} />
        </div>
        <div className="flex-grow min-w-0">
          <h3 className="text-sm sm:text-base font-medium text-gray-900 sm:truncate">
            {competition.title}
          </h3>
          <div className="flex items-center mt-1 text-xs sm:text-sm text-gray-500">
            <svg
              className="flex-shrink-0 mr-1.5 h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <span>
              Closes: {new Date(competition.closing_date).toLocaleDateString()}
            </span>
          </div>
        </div>
        <div className="flex-shrink-0 mt-2 sm:mt-0 sm:ml-4">
          <Badge
            variant={
              competition.won
                ? 'success'
                : competition.status === 'ignored'
                ? 'error'
                : 'primary'
            }
          >
            {competition.won ? 'Won' : capitalizeFirstLetter(competition.status)}
          </Badge>
        </div>
      </div>
      <CardFooter className="bg-gray-50 px-4 py-3">
        <div className="flex flex-wrap justify-end gap-2">
          {!isWon && !isEntered && (
            <Button
              size="sm"
              variant="default"
              onClick={() => onUpdateStatus(competition.id, 'entered', false)}
            >
              Mark as Entered
            </Button>
          )}
          {!isWon && (
            <Button
              size="sm"
              variant="default"
              onClick={() => onUpdateStatus(competition.id, competition.status, true)}
            >
              Mark as Won
            </Button>
          )}
          {!isIgnored && !isWon && (
            <Button
              size="sm"
              variant="outline"
              onClick={() => onUpdateStatus(competition.id, 'ignored', false)}
            >
              Ignore
            </Button>
          )}
        </div>
      </CardFooter>
    </Card>
  );
}

// Helper function to capitalize first letter
function capitalizeFirstLetter(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export default Competitions;
