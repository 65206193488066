import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';

interface Achievement {
  id: string;
  name: string;
  description: string;
  icon: string;
  category: string;
  max_progress: number;
  progress: number;
  earned_at: string | null;
}

const Achievements: React.FC = () => {
  const [achievements, setAchievements] = useState<Achievement[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedAchievement, setSelectedAchievement] = useState<Achievement | null>(null);

  useEffect(() => {
    fetchAchievements();
  }, []);

  const fetchAchievements = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No user logged in');

      const { data: allAchievements, error: achievementsError } = await supabase
        .from('achievements')
        .select('*');

      if (achievementsError) throw achievementsError;

      const { data: userAchievements, error: userAchievementsError } = await supabase
        .from('user_achievements')
        .select('*')
        .eq('user_id', user.id);

      if (userAchievementsError) throw userAchievementsError;

      const combinedAchievements = allAchievements.map((achievement: Achievement) => {
        const userAchievement = userAchievements.find(ua => ua.achievement_name === achievement.name);
        return {
          ...achievement,
          progress: userAchievement ? userAchievement.progress : 0,
          earned_at: userAchievement ? userAchievement.earned_at : null
        };
      });

      setAchievements(combinedAchievements);
    } catch (error) {
      console.error('Error fetching achievements:', error);
    } finally {
      setLoading(false);
    }
  };

  const renderAchievement = (achievement: Achievement) => {
    const iconParts = achievement.icon.split(' ');
    const emoji = iconParts[0];
    const number = iconParts[1] || achievement.max_progress.toString();

    return (
      <div 
        key={achievement.id} 
        className="bg-white rounded-lg shadow-md p-3 flex flex-col justify-between border border-purple-200 aspect-square relative cursor-pointer hover:shadow-lg transition-shadow"
        onClick={() => setSelectedAchievement(achievement)}
      >
        {achievement.earned_at && (
          <div className="absolute top-1 right-1 w-4 h-4 bg-green-500 rounded-full flex items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-2 w-2 text-white" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
            </svg>
          </div>
        )}
        <div className="flex items-center justify-center space-x-1 mb-2">
          <span className="text-2xl">{emoji}</span>
          <span className="text-lg font-semibold text-purple-800">{number}</span>
        </div>
        <h3 className="text-sm font-semibold text-center mb-1 text-purple-800 truncate">{achievement.name}</h3>
        <div className="w-full bg-gray-200 rounded-full h-2 mb-1 overflow-hidden">
          <div
            className="bg-blue-500 h-full"
            style={{ width: `${(achievement.progress / achievement.max_progress) * 100}%` }}
          ></div>
        </div>
        <p className="text-xs text-gray-500 text-center">
          {achievement.progress} / {achievement.max_progress}
        </p>
      </div>
    );
  };

  const AchievementModal: React.FC<{ achievement: Achievement, onClose: () => void }> = ({ achievement, onClose }) => {
    const iconParts = achievement.icon.split(' ');
    const emoji = iconParts[0];
    const number = iconParts[1] || achievement.max_progress.toString();

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
        <div className="bg-white rounded-lg p-6 max-w-sm w-full">
          <div className="flex items-center justify-center space-x-2 mb-4">
            <span className="text-4xl">{emoji}</span>
            <span className="text-2xl font-semibold text-purple-800">{number}</span>
          </div>
          <h2 className="text-xl font-bold text-center mb-2">{achievement.name}</h2>
          <p className="text-gray-600 text-center mb-4">{achievement.description}</p>
          <div className="w-full bg-gray-200 rounded-full h-2 mb-2 overflow-hidden">
            <div
              className="bg-blue-500 h-full"
              style={{ width: `${(achievement.progress / achievement.max_progress) * 100}%` }}
            ></div>
          </div>
          <p className="text-sm text-gray-500 text-center mb-4">
            {achievement.progress} / {achievement.max_progress}
          </p>
          {achievement.earned_at && (
            <p className="text-green-500 text-center font-semibold mb-4">Earned!</p>
          )}
          <button 
            className="w-full bg-purple-600 text-white py-2 rounded-lg hover:bg-purple-700 transition-colors"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    );
  };

  const achievedCount = achievements.filter(a => a.earned_at).length;
  const totalCount = achievements.length;

  if (loading) {
    return <div className="p-4 text-center">Loading achievements...</div>;
  }

  return (
    <div className="w-full min-h-screen bg-[#EEF0FD] p-4">
      <div className="max-w-7xl mx-auto">
        <div className="bg-white rounded-lg shadow-md border border-purple-200 p-4 mb-4">
          <h1 className="text-2xl font-bold text-purple-800 text-center">Achievements</h1>
          <p className="text-sm text-gray-600 text-center mt-2">
            You've achieved {achievedCount} out of {totalCount} achievements
          </p>
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-4">
          {achievements.map(renderAchievement)}
        </div>
      </div>
      {selectedAchievement && (
        <AchievementModal 
          achievement={selectedAchievement} 
          onClose={() => setSelectedAchievement(null)} 
        />
      )}
    </div>
  );
};

export default Achievements;