import React from 'react';
import NavBar from './NavBar';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div className="flex min-h-screen bg-[#EEF0FD]">
      <NavBar />
      <div className="flex-1 ml-16 lg:ml-64">
        <main className="p-4 lg:p-8 overflow-y-auto h-full">
          {children}
        </main>
      </div>
    </div>
  );
};

export default Layout;