import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from '../supabaseClient';

interface BlogPost {
  id: string;
  title: string;
  slug: string;
  published_at: string;
}

const LatestPosts: React.FC = () => {
  const [latestPosts, setLatestPosts] = useState<BlogPost[]>([]);

  useEffect(() => {
    fetchLatestPosts();
  }, []);

  const fetchLatestPosts = async () => {
    const { data, error } = await supabase
      .from('blog_posts')
      .select('id, title, slug, published_at')
      .order('published_at', { ascending: false })
      .limit(5);

    if (error) console.error('Error fetching latest posts:', error);
    else setLatestPosts(data || []);
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-6 mb-8">
      <h2 className="text-2xl font-bold mb-4">Latest Posts</h2>
      <ul className="space-y-4">
        {latestPosts.map((post) => (
          <li key={post.id}>
            <Link to={`/blog/${post.slug}`} className="block hover:bg-gray-100 p-2 rounded">
              <h3 className="font-semibold">{post.title}</h3>
              <p className="text-sm text-gray-500">
                {new Date(post.published_at).toLocaleDateString()}
              </p>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LatestPosts;