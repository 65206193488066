import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

interface FAQItem {
  question: string;
  answer: string;
}

const faqData: FAQItem[] = [
  {
    question: "What is Sweepzy.io?",
    answer: "Sweepzy.io is a comprehensive platform for sweepstakers and compers. We provide a range of tools and resources to help users enter and win more prizes."
  },
  {
    question: "How do I join?",
    answer: "You can join by signing up for a free account and adding your email to our waiting list. Our website will be fully launching in September 2024."
  },
  {
    question: "What features do you offer?",
    answer: "Sweepzy.io offers a range of features to help users succeed in the world of sweepstakes and competitions. These include beautifully curated dashboards, automatic competition tracking, and a league table that shows how users rank against other members."
  },
  {
    question: "How can I maximize my chances of winning?",
    answer: "Sweepzy.io offers a range of tools and resources to help users maximize their chances of winning. These include expert tips and advice, automatic entry tracking, and detailed stats and analysis."
  },
  {
    question: "Is there a cost to join Sweepzy.io?",
    answer: "No, there is no cost to join Sweepzy.io. We do offer paid plans, but you can get started with our free to use tools and sign up for additionally services later."
  },
  {
    question: "Is Sweepzy.io only for experienced sweepstakers and compers?",
    answer: "No, Sweepzy.io is suitable for sweepstakers and compers of any level of experience."
  },
  {
    question: "Which countries do you cover?",
    answer: "We currently only operate in the UK. However, we are working on expanding to other countries."
  },
  {
    question: "How can I contact Sweepzy.io if I have questions or need help?",
    answer: "You can contact us by email at: sayhello@sweepzy.io or post on our forum if you're logged into to your account."
  }
];

const FAQItem: React.FC<{ item: FAQItem }> = ({ item }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border border-gray-200 rounded-lg mb-4">
      <button
        className="flex justify-between items-center w-full p-4 text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="font-semibold text-sm sm:text-base">{item.question}</span>
        {isOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
      </button>
      {isOpen && (
        <div className="p-4 pt-0">
          <p className="text-sm sm:text-base">{item.answer}</p>
        </div>
      )}
    </div>
  );
};

const FAQ: React.FC = () => {
  return (
    <section className="py-16 sm:py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <h2 className="text-2xl sm:text-3xl font-bold text-center mb-6 sm:mb-8">Frequently Asked Questions</h2>
        <p className="text-center mb-8 sm:mb-12 text-sm sm:text-base">We have put together some commonly asked questions</p>
        <div className="max-w-3xl mx-auto">
          {faqData.map((item, index) => (
            <FAQItem key={index} item={item} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQ;