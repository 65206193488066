import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { supabase } from './supabaseClient';
import { ThemeProvider } from './ThemeContext';
import { HelmetProvider } from 'react-helmet-async';
import { ChakraProvider } from '@chakra-ui/react';
import { ToastProvider } from './components/ui/toast';
import { usePageTracking } from './utils/usePageTracking';
import { useSessionTracking } from './utils/useSessionTracking';
import { trackDeviceInfo } from './utils/deviceInfo';
import { timeTrackingUtility } from './utils/timeTracking';

// Components
import Header from './components/Header';
import Hero from './components/Hero';
import Stats from './components/Stats';
import Features from './components/Features';
import Analysis from './components/Analysis';
import Pricing from './components/Pricing';
import FAQ from './components/FAQ';
import Footer from './components/Footer';
import Login from './components/Login';
import SignUp from './components/SignUp';
import Dashboard from './components/Dashboard';
import Competitions from './components/Competitions';
import AddCompetition from './components/AddCompetition';
import OpenCompetitions from './components/OpenCompetitions';
import Leaderboard from './components/Leaderboard';
import Achievements from './components/Achievements';
import Profile from './components/Profile';
import PublicProfile from './components/PublicProfile';
import Settings from './components/Settings';
import Layout from './components/Layout';
import BlogPage from './components/BlogPage';
import BlogPost from './components/BlogPost';
import ForumList from './components/ForumList';
import ForumPage from './components/ForumPage';
import PostPage from './components/PostPage';
import CreatePostForm from './components/CreatePostForm';
import EditPostForm from './components/EditPostForm';
import ScrollToTop from './components/ScrollToTop';
import Contact from './components/Contact';
import AboutUs from './components/AboutUs';
import TermsOfService from './components/TermsOfService';
import PrivacyPolicy from './components/PrivacyPolicy';
import CookiePolicy from './components/CookiePolicy';

// Google Analytics
declare global {
  interface Window {
    dataLayer: any[];
    gtag?: (...args: any[]) => void;
  }
}

const GoogleAnalytics: React.FC = () => {
  const location = useLocation();
  const [isGtagReady, setIsGtagReady] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-FDHJNS62C7`;
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag(...args: any[]) {
        window.dataLayer.push(arguments);
      }
      window.gtag = gtag;
      gtag('js', new Date());
      gtag('config', 'G-FDHJNS62C7');
      setIsGtagReady(true);
    };

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (isGtagReady && window.gtag) {
      window.gtag('config', 'G-FDHJNS62C7', {
        page_path: location.pathname + location.search,
      });
    }
  }, [location, isGtagReady]);

  return null;
};

const LandingPage: React.FC = () => {
  const pricingRef = useRef<HTMLDivElement>(null);
  const faqRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      setTimeout(() => {
        const element = document.querySelector(location.hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0);
    }
  }, [location]);

  return (
    <>
      <Hero />
      <Stats />
      <Features />
      <Analysis />
      <div id="pricing" ref={pricingRef}>
        <Pricing />
      </div>
      <div id="faq" ref={faqRef}>
        <FAQ />
      </div>
      <ScrollToTop />
    </>
  );
};

const App: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);
  const [userId, setUserId] = useState<string | undefined>(undefined);
  const [username, setUsername] = useState<string | undefined>(undefined);

  useEffect(() => {
    const checkUser = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      setIsLoggedIn(!!user);
      setUserId(user?.id);
      setUsername(user?.user_metadata?.username);
      if (user) {
        trackDeviceInfo(user.id, user.user_metadata.username);
        timeTrackingUtility.startTracking('total');
      }
    };
    checkUser();

    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      if (event === 'SIGNED_IN' && session?.user) {
        setIsLoggedIn(true);
        setUserId(session.user.id);
        setUsername(session.user.user_metadata?.username);
        trackDeviceInfo(session.user.id, session.user.user_metadata.username);
        timeTrackingUtility.startTracking('total');
      } else if (event === 'SIGNED_OUT') {
        if (userId && username) {
          timeTrackingUtility.stopTracking('total', userId, username);
        }
        setIsLoggedIn(false);
        setUserId(undefined);
        setUsername(undefined);
      }
    });

    return () => {
      authListener.subscription.unsubscribe();
      if (isLoggedIn && userId && username) {
        timeTrackingUtility.stopTracking('total', userId, username);
      }
    };
  }, []);

  useEffect(() => {
    // AdSense script
    const script = document.createElement('script');
    script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4885319957731063";
    script.async = true;
    script.crossOrigin = "anonymous";
    document.head.appendChild(script);

    // AdSense verification meta tag
    const meta = document.createElement('meta');
    meta.name = "google-adsense-account";
    meta.content = "ca-pub-4885319957731063";
    document.head.appendChild(meta);

    return () => {
      document.head.removeChild(script);
      document.head.removeChild(meta);
    };
  }, []);

  if (isLoggedIn === null) {
    return <div>Loading...</div>;
  }

  return (
    <HelmetProvider>
      <ChakraProvider>
        <ThemeProvider>
          <ToastProvider>
            <Router>
              <AppContent isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} userId={userId} username={username} />
            </Router>
          </ToastProvider>
        </ThemeProvider>
      </ChakraProvider>
    </HelmetProvider>
  );
};

interface AppContentProps {
  isLoggedIn: boolean;
  setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean | null>>;
  userId: string | undefined;
  username: string | undefined;
}

const AppContent: React.FC<AppContentProps> = ({ isLoggedIn, setIsLoggedIn, userId, username }) => {
  const location = useLocation();
  const navigate = useNavigate();

  usePageTracking(userId || '', username || '');
  useSessionTracking(userId || '', username || '');

  const showHeaderFooter = !isLoggedIn || location.pathname === '/' || location.pathname.startsWith('/blog') || location.pathname === '/contact' || location.pathname === '/about-us' || location.pathname === '/terms-of-service' || location.pathname === '/privacy-policy' || location.pathname === '/cookie-policy';

  const ProtectedRoute: React.FC<{ element: React.ReactElement }> = ({ element }) => {
    return isLoggedIn ? element : <Navigate to="/login" replace />;
  };

  const handleNavigation = (path: string) => {
    if (location.pathname === '/' && (path === '#pricing' || path === '#faq')) {
      const element = document.querySelector(path);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      navigate('/' + path);
    }
  };

  return (
    <>
      <GoogleAnalytics />
      {showHeaderFooter && <Header isLoggedIn={isLoggedIn} onLogout={() => setIsLoggedIn(false)} onNavigation={handleNavigation} />}
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<Login onLogin={() => setIsLoggedIn(true)} />} />
        <Route path="/signup" element={<SignUp onSignUp={() => setIsLoggedIn(true)} />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/blog/:slug" element={<BlogPost />} />
        <Route path="/profile/:username" element={<PublicProfile />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />

        <Route path="/dashboard" element={<ProtectedRoute element={<Layout><Dashboard /></Layout>} />} />
        <Route path="/competitions" element={<ProtectedRoute element={<Layout><Competitions /></Layout>} />} />
        <Route path="/add-competition" element={<ProtectedRoute element={<Layout><AddCompetition /></Layout>} />} />
        <Route path="/open-competitions" element={<ProtectedRoute element={<Layout><OpenCompetitions /></Layout>} />} />
        <Route path="/leaderboard" element={<ProtectedRoute element={<Layout><Leaderboard /></Layout>} />} />
        <Route path="/achievements" element={<ProtectedRoute element={<Layout><Achievements /></Layout>} />} />
        <Route path="/profile" element={<ProtectedRoute element={<Layout><Profile /></Layout>} />} />
        <Route path="/settings" element={<ProtectedRoute element={<Layout><Settings /></Layout>} />} />

        <Route path="/forums" element={<ProtectedRoute element={<Layout><ForumList /></Layout>} />} />
        <Route path="/forum/:forumId" element={<ProtectedRoute element={<Layout><ForumPage /></Layout>} />} />
        <Route path="/post/:postId" element={<ProtectedRoute element={<Layout><PostPage /></Layout>} />} />
        <Route path="/create-post/:forumId" element={<ProtectedRoute element={<Layout><CreatePostForm forumId="" onPostCreated={() => {}} /></Layout>} />} />
        <Route path="/edit-post/:postId" element={<ProtectedRoute element={<Layout><EditPostForm /></Layout>} />} />

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      {showHeaderFooter && <Footer />}
      <ScrollToTop />
    </>
  );
};

export default App;