import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { logUserActivity } from './activityTracker';

export function usePageTracking(userId: string, username: string) {
  const location = useLocation();

  useEffect(() => {
    const startTime = performance.now();

    return () => {
      const endTime = performance.now();
      const timeSpentSeconds = Math.round((endTime - startTime) / 1000);

      logUserActivity(userId, username, {
        action_type: 'page_view',
        details: {
          path: location.pathname,
          time_spent_seconds: timeSpentSeconds
        }
      });
    };
  }, [location, userId, username]);
}