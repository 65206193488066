import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import ladyWithLaptop from './assets/lady.svg';

interface LoginProps {
  onLogin: () => void;
}

const Login: React.FC<LoginProps> = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const { data, error } = await supabase.auth.signInWithPassword({ email, password });
      if (error) throw error;
      
      if (data.user) {
        // Update last_login and login streak
        const { error: updateError } = await supabase.rpc('update_login_info', { user_id: data.user.id });

        if (updateError) console.error('Error updating login info:', updateError);
      }

      onLogin();
      navigate('/dashboard');
    } catch (error: any) {
      setError(error.error_description || error.message);
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-[#EEF0FD]">
      <main className="flex-grow flex items-start justify-center pt-40">
        <div className="bg-white p-6 rounded-lg shadow-md w-80 relative">
          <h2 className="text-2xl font-bold mb-4">Login Now</h2>
          <img 
            src={ladyWithLaptop}
            alt="Woman with laptop" 
            className="absolute -top-16 -left-12 w-28 h-28"
          />
          {error && <p className="text-red-500 mb-4 text-sm">{error}</p>}
          <form onSubmit={handleLogin} className="space-y-4">
            <input
              type="email"
              placeholder="Your Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full p-2 border rounded bg-[#F8F9FD] text-sm"
              required
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full p-2 border rounded bg-[#F8F9FD] text-sm"
              required
            />
            <button type="submit" className="w-full bg-[#4361EE] text-white py-2 rounded hover:bg-[#3A0CA3] text-sm">
              Login
            </button>
          </form>
          <p className="mt-4 text-center text-xs">
            Don't have an account? <a href="/signup" className="text-[#4361EE] font-semibold">Sign Up</a>
          </p>
        </div>
      </main>
    </div>
  );
};

export default Login;