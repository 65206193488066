import React from 'react';

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="flex-grow container mx-auto px-4 py-8">
      <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-md p-6 md:p-8">
        <h1 className="text-3xl font-bold text-[#171938] mb-6 text-center">Privacy Policy</h1>
        
        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-[#171938] mb-4">1. Information We Collect</h2>
          <p className="text-gray-700 mb-4">
            We collect information you provide directly to us, such as when you create an account, update your profile, or use our services. This may include your name, email address, and other personal information.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-[#171938] mb-4">2. How We Use Your Information</h2>
          <p className="text-gray-700 mb-4">
            We use the information we collect to provide, maintain, and improve our services, to communicate with you, and to personalize your experience on Sweepzy.io.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-[#171938] mb-4">3. Information Sharing and Disclosure</h2>
          <p className="text-gray-700 mb-4">
            We do not share your personal information with third parties except as described in this policy. We may share information with your consent, to comply with laws, to protect your rights, or to fulfill business obligations.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-[#171938] mb-4">4. Data Security</h2>
          <p className="text-gray-700 mb-4">
            We take reasonable measures to help protect information about you from loss, theft, misuse and unauthorized access, disclosure, alteration and destruction.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-[#171938] mb-4">5. Cookies and Similar Technologies</h2>
          <p className="text-gray-700 mb-4">
            We use cookies and similar technologies to collect information about your browsing activities and to personalize your experience on our site.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-[#171938] mb-4">6. Your Rights</h2>
          <p className="text-gray-700 mb-4">
            You have the right to access, correct, or delete your personal information. You can also object to processing of your personal information or ask us to restrict processing in certain circumstances.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold text-[#171938] mb-4">7. Changes to This Policy</h2>
          <p className="text-gray-700 mb-4">
            We may update this privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page.
          </p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;