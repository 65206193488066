import { supabase } from '../supabaseClient';

interface ActivityData {
  action_type: string;
  details?: object;
}

export async function logUserActivity(userId: string, username: string, data: ActivityData) {
  try {
    const { action_type, details } = data;

    // Update user_activity_today
    const { error: todayError } = await supabase.rpc('update_user_activity_today', {
      p_user_id: userId,
      p_username: username,
      p_action_type: action_type,
      p_details: details || {}
    });

    if (todayError) throw todayError;

    // Update user_activity_alltime
    const { error: alltimeError } = await supabase.rpc('update_user_activity_alltime', {
      p_user_id: userId,
      p_username: username,
      p_action_type: action_type,
      p_details: details || {}
    });

    if (alltimeError) throw alltimeError;

    console.log('Activity logged successfully:', action_type);
  } catch (error) {
    console.error('Error logging activity:', error);
  }
}

// Add this type to the existing file
export type DebouncedFunction = {
  (...args: any[]): void;
  cancel: () => void;
};