import { useEffect } from 'react';
import { logUserActivity } from './activityTracker';

export function useSessionTracking(userId: string, username: string) {
  useEffect(() => {
    const interval = setInterval(() => {
      logUserActivity(userId, username, { 
        action_type: 'session_ping',
        details: {} // Add any additional details you want to track
      });
    }, 5 * 60 * 1000); // Ping every 5 minutes

    return () => clearInterval(interval);
  }, [userId, username]);
}