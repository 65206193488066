import React from 'react';

const TermsOfService: React.FC = () => {
  return (
    <div className="flex-grow container mx-auto px-4 py-8">
      <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-md p-6 md:p-8">
        <h1 className="text-3xl font-bold text-[#171938] mb-6 text-center">Terms of Service</h1>
        
        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-[#171938] mb-4">1. Acceptance of Terms</h2>
          <p className="text-gray-700 mb-4">
            By accessing or using Sweepzy.io, you agree to be bound by these Terms of Service. If you disagree with any part of the terms, you may not access the service.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-[#171938] mb-4">2. Description of Service</h2>
          <p className="text-gray-700 mb-4">
            Sweepzy.io provides a platform for users to discover, enter, and track sweepstakes and competitions. We do not run, endorse, or guarantee any of the competitions listed on our platform.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-[#171938] mb-4">3. User Responsibilities</h2>
          <p className="text-gray-700 mb-4">
            Users are responsible for ensuring they are eligible to enter the competitions listed on Sweepzy.io. Users must not use our service for any illegal or unauthorized purpose.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-[#171938] mb-4">4. Account Creation and Security</h2>
          <p className="text-gray-700 mb-4">
            To use certain features of our service, you may be required to create an account. You are responsible for maintaining the confidentiality of your account and password.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-[#171938] mb-4">5. Intellectual Property</h2>
          <p className="text-gray-700 mb-4">
            The content on Sweepzy.io, including text, graphics, logos, and software, is the property of Sweepzy.io or its content suppliers and is protected by copyright laws.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-[#171938] mb-4">6. Limitation of Liability</h2>
          <p className="text-gray-700 mb-4">
            Sweepzy.io shall not be liable for any indirect, incidental, special, consequential or punitive damages resulting from your use of or inability to use the service.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold text-[#171938] mb-4">7. Changes to Terms</h2>
          <p className="text-gray-700 mb-4">
            We reserve the right to modify or replace these Terms at any time. It is your responsibility to check these Terms periodically for changes.
          </p>
        </section>
      </div>
    </div>
  );
};

export default TermsOfService;