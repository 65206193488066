import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from '../supabaseClient';

interface Forum {
  id: string;
  name: string;
  description: string;
  post_count: number;
  last_post_time: string;
}

interface RecentPost {
  id: string;
  title: string;
  author: string;
  forum: string;
  comment_count: number;
  created_at: string;
}

const ForumList: React.FC = () => {
  const [forums, setForums] = useState<Forum[]>([]);
  const [recentPosts, setRecentPosts] = useState<RecentPost[]>([]);

  useEffect(() => {
    fetchForums();
    fetchRecentPosts();
  }, []);

  const fetchForums = async () => {
    try {
      const { data, error } = await supabase
        .from('forums')
        .select('*, posts(count)')
        .order('name', { ascending: true });
      if (error) throw error;
      setForums(data?.map(forum => ({
        ...forum,
        post_count: forum.posts?.[0]?.count || 0
      })) || []);
    } catch (error) {
      console.error('Error fetching forums:', error);
    }
  };

  const fetchRecentPosts = async () => {
    try {
      const { data, error } = await supabase
        .from('posts')
        .select(`
          id,
          title,
          created_at,
          author_username,
          forum_name,
          comments (count)
        `)
        .order('created_at', { ascending: false })
        .limit(5);
      if (error) throw error;
      setRecentPosts(data?.map(post => ({
        id: post.id,
        title: post.title,
        author: post.author_username,
        forum: post.forum_name,
        comment_count: post.comments?.[0]?.count || 0,
        created_at: new Date(post.created_at).toLocaleString()
      })) || []);
    } catch (error) {
      console.error('Error fetching recent posts:', error);
    }
  };

  return (
    <div className="w-full min-h-screen bg-[#EEF0FD] p-4 sm:p-6">
      <div className="max-w-6xl mx-auto">
        <div className="bg-white rounded-lg shadow-md border border-purple-200 p-4 mb-6">
          <h1 className="text-2xl font-bold text-purple-800 text-center">Forums</h1>
        </div>
        
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-8">
          {forums.map(forum => (
            <Link key={forum.id} to={`/forum/${forum.id}`} className="bg-white rounded-lg shadow-md p-4 hover:shadow-lg transition-shadow border border-purple-200">
              <h3 className="text-lg font-semibold mb-2 text-purple-800">{forum.name}</h3>
              <p className="text-gray-600 text-sm mb-3">{forum.description}</p>
              <div className="flex justify-between items-center text-sm">
                <span className="text-purple-600 font-medium">Posts: {forum.post_count}</span>
                <span className="text-gray-500">
                  {forum.last_post_time ? `Last post: ${new Date(forum.last_post_time).toLocaleDateString()}` : 'No posts yet'}
                </span>
              </div>
            </Link>
          ))}
        </div>

        <div className="bg-white rounded-lg shadow-md border border-purple-200 p-4">
          <h2 className="text-xl sm:text-2xl font-bold mb-4 text-purple-800">Recent Posts</h2>
          <div className="divide-y divide-gray-200">
            {recentPosts.map(post => (
              <Link key={post.id} to={`/post/${post.id}`} className="block py-3 hover:bg-purple-50 transition-colors">
                <h4 className="font-semibold mb-1 text-sm sm:text-base text-purple-700">{post.title}</h4>
                <p className="text-xs sm:text-sm text-gray-600">
                  by {post.author} in {post.forum}
                </p>
                <p className="text-xs sm:text-sm text-gray-500 mt-1">
                  {post.comment_count} comments • {post.created_at}
                </p>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForumList;