import React, { useState } from 'react';
import { supabase } from '../supabaseClient';

const AddCompetition: React.FC = () => {
  const [formData, setFormData] = useState({
    title: '',
    promoter: '',
    prizeValue: '',
    prizeType: '',
    endDate: '',
    url: '',
    competitionType: '',
    entryMethod: '',
  });

  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const prizeTypes = [
    'Cash Prize',
    'Holiday',
    'Electronics',
    'Gift Card',
    'Car',
    'Experience',
    'Beauty Products',
    'Fashion Items',
    'Home Goods',
    'Food & Drink',
    'Sports Equipment',
    'Books',
    'Movie & Music',
    'Baby & Kids Items',
  ];

  const competitionTypes = [
    'Q&A',
    'Online Form',
    'Like & Share',
    'Photo/Video Submission',
    'Purchase Required',
    'Free Entry',
  ];

  const entryMethods = [
    'Website',
    'Social Media',
    'SMS',
    'Post',
    'In-Store',
    'Email',
  ];

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);

    try {
      const { data, error } = await supabase
        .from('competitions')
        .insert([
          {
            title: formData.title,
            promoter: formData.promoter,
            value: formData.prizeValue,
            prize_type: formData.prizeType,
            closing_date: formData.endDate,
            url: formData.url,
            competition_type: formData.competitionType,
            entry_method: formData.entryMethod,
          }
        ]);

      if (error) throw error;
      
      setSuccess('Competition added successfully!');
      setFormData({
        title: '',
        promoter: '',
        prizeValue: '',
        prizeType: '',
        endDate: '',
        url: '',
        competitionType: '',
        entryMethod: '',
      });
    } catch (error) {
      console.error('Error adding competition:', error);
      setError('Failed to add competition. Please try again.');
    }
  };

  return (
    <div className="p-6">
      <div className="bg-white shadow-md rounded-lg overflow-hidden max-w-3xl">
        <div className="p-6">
          <h1 className="text-2xl font-bold mb-6">Add a New Competition</h1>
          {error && <p className="text-red-500 mb-4">{error}</p>}
          {success && <p className="text-green-500 mb-4">{success}</p>}
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="title" className="block mb-1 text-sm font-medium">Title</label>
              <input
                type="text"
                id="title"
                name="title"
                value={formData.title}
                onChange={handleChange}
                required
                className="w-full p-2 border rounded text-sm"
              />
            </div>
            <div>
              <label htmlFor="promoter" className="block mb-1 text-sm font-medium">Promoter or Brand</label>
              <input
                type="text"
                id="promoter"
                name="promoter"
                value={formData.promoter}
                onChange={handleChange}
                required
                className="w-full p-2 border rounded text-sm"
              />
            </div>
            <div>
              <label htmlFor="prizeValue" className="block mb-1 text-sm font-medium">Prize Value</label>
              <input
                type="number"
                id="prizeValue"
                name="prizeValue"
                value={formData.prizeValue}
                onChange={handleChange}
                required
                className="w-full p-2 border rounded text-sm"
              />
            </div>
            <div>
              <label htmlFor="prizeType" className="block mb-1 text-sm font-medium">Prize Type</label>
              <select
                id="prizeType"
                name="prizeType"
                value={formData.prizeType}
                onChange={handleChange}
                required
                className="w-full p-2 border rounded text-sm"
              >
                <option value="">Select Prize Type</option>
                {prizeTypes.map((type) => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="endDate" className="block mb-1 text-sm font-medium">End Date</label>
              <input
                type="date"
                id="endDate"
                name="endDate"
                value={formData.endDate}
                onChange={handleChange}
                required
                className="w-full p-2 border rounded text-sm"
              />
            </div>
            <div>
              <label htmlFor="url" className="block mb-1 text-sm font-medium">Competition URL</label>
              <input
                type="url"
                id="url"
                name="url"
                value={formData.url}
                onChange={handleChange}
                required
                className="w-full p-2 border rounded text-sm"
              />
            </div>
            <div>
              <label htmlFor="competitionType" className="block mb-1 text-sm font-medium">Competition Type</label>
              <select
                id="competitionType"
                name="competitionType"
                value={formData.competitionType}
                onChange={handleChange}
                required
                className="w-full p-2 border rounded text-sm"
              >
                <option value="">Select Competition Type</option>
                {competitionTypes.map((type) => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="entryMethod" className="block mb-1 text-sm font-medium">Entry Method</label>
              <select
                id="entryMethod"
                name="entryMethod"
                value={formData.entryMethod}
                onChange={handleChange}
                required
                className="w-full p-2 border rounded text-sm"
              >
                <option value="">Select Entry Method</option>
                {entryMethods.map((method) => (
                  <option key={method} value={method}>{method}</option>
                ))}
              </select>
            </div>
            <button type="submit" className="w-full bg-[#171938] text-white px-4 py-2 rounded hover:bg-[#9A38F5] text-sm">
              Add Competition
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddCompetition;