import React from 'react';
import { ArrowRight } from 'lucide-react';
import heroImage from './assets/dashboard.webp';

const Features: React.FC = () => (
  <section className="py-16 sm:py-20">
    <div className="container mx-auto px-4">
      <h2 className="text-3xl sm:text-4xl font-bold text-center mb-8 sm:mb-12">Take your sweepstaking to the next level!</h2>
      
      {/* Main Features */}
      <div className="flex flex-col lg:flex-row items-center mb-16 sm:mb-20">
        <div className="w-full lg:w-1/2 mb-8 lg:mb-0">
          <img src={heroImage} alt="Feature illustration" className="w-full max-w-md mx-auto" />
        </div>
        <div className="w-full lg:w-1/2 lg:pl-12">
          <FeatureItem
            icon="🖥️"
            title="Easy to navigate interface"
            description="Our beautifully curated dashboards make navigation a dream. With our clean and intuitive layout, you can apply for competitions in just a few clicks. Say goodbye to clunky and confusing designs and join our platform today!"
          />
          <FeatureItem
            icon="📊"
            title="Track your progress"
            description="Automatically track your competition entries and view your stats. See how you rank against other members on our league table."
          />
          <FeatureItem
            icon="👥"
            title="Active Community"
            description="Collaborate with an active community of sweepstakers and compers who provide support and share tips and advice."
          />
        </div>
      </div>

      {/* Performance Analysis */}
      <div className="flex flex-col lg:flex-row items-center mb-16 sm:mb-20 bg-gray-50 rounded-lg p-8 sm:p-12">
        <div className="w-full lg:w-1/2 mb-8 lg:mb-0 lg:pr-12">
          <h3 className="text-2xl sm:text-3xl font-bold mb-4">Effortlessly analyze your performance</h3>
          <p className="mb-6 text-sm sm:text-base">
            Maximize your chances of winning with our expert comping & sweepstaking platform. 
            Analyze your entries and stats, and join the community of successful compers. 
            Don't settle for mediocre tools — join the biggest community and start winning today!
          </p>
          <button className="bg-blue-600 text-white px-4 py-2 sm:px-6 sm:py-2 rounded-full flex items-center hover:bg-blue-700 transition-colors text-sm sm:text-base">
            Discover More <ArrowRight className="ml-2" size={20} />
          </button>
        </div>
        <div className="w-full lg:w-1/2 flex justify-center lg:justify-end">
          <img src={heroImage} alt="Performance Analysis" className="w-full max-w-md" />
        </div>
      </div>

      {/* Why Choose Us */}
      <div className="flex flex-col lg:flex-row items-center">
        <div className="w-full lg:w-1/2 mb-8 lg:mb-0">
          <img src={heroImage} alt="Why Choose Us" className="w-full max-w-md mx-auto" />
        </div>
        <div className="w-full lg:w-1/2 lg:pl-12">
          <h3 className="text-2xl sm:text-3xl font-bold mb-4">Why choose us?</h3>
          <p className="mb-6 text-sm sm:text-base">
            People love our app for these wonderful and efficient features which give the user a complete freedom.
          </p>
          <div className="grid grid-cols-2 gap-4">
            <FeatureItem icon="⏱️" title="Save Time" />
            <FeatureItem icon="🛠️" title="Autonomous Tools" />
            <FeatureItem icon="😌" title="Stress Free" />
            <FeatureItem icon="💰" title="Flexible Pricing" />
            <FeatureItem icon="🌱" title="Growing Community" />
            <FeatureItem icon="🏆" title="Best User Experience" />
          </div>
          <button className="bg-blue-600 text-white px-4 py-2 sm:px-6 sm:py-2 rounded-full flex items-center mt-6 hover:bg-blue-700 transition-colors text-sm sm:text-base">
            Discover More <ArrowRight className="ml-2" size={20} />
          </button>
        </div>
      </div>
    </div>
  </section>
);

const FeatureItem: React.FC<{ icon: string; title: string; description?: string }> = ({ icon, title, description }) => (
  <div className="flex items-start mb-6">
    <div className="text-2xl mr-4">{icon}</div>
    <div>
      <h4 className="font-bold mb-2 text-base sm:text-lg">{title}</h4>
      {description && <p className="text-gray-600 text-sm sm:text-base">{description}</p>}
    </div>
  </div>
);

export default Features;