import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { ChevronUp, ChevronDown, ExternalLink, Trophy, Star, Award, MessageSquare } from 'lucide-react';

interface LeaderboardEntry {
  rank: number;
  username: string;
  entries: number;
  wins: number;
  achievements: number;
  forumActivity: number;
  totalPoints: number;
  avatar_url: string;
}

const pointAllocations = [
  { action: "Per entry", points: 1, icon: <Star size={16} />, color: "bg-blue-500" },
  { action: "For adding a comp", points: 2, icon: <Star size={16} />, color: "bg-green-500" },
  { action: "For a win", points: 5, icon: <Trophy size={16} />, color: "bg-yellow-500" },
  { action: "Per achievement", points: 5, icon: <Award size={16} />, color: "bg-purple-500" },
  { action: "Per forum post or comment", points: 1, icon: <MessageSquare size={16} />, color: "bg-pink-500" },
];

const COLORS = ['#9333EA', '#B366F2', '#D399FF', '#E6CCFF', '#FF6384'];

const Leaderboard: React.FC = () => {
  const [leaderboardData, setLeaderboardData] = useState<LeaderboardEntry[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showAllocation, setShowAllocation] = useState(false);
  const [userRank, setUserRank] = useState<number | null>(null);

  useEffect(() => {
    fetchLeaderboardData();
  }, []);

  const fetchLeaderboardData = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No user logged in');

      const { data: leaderboardData, error: leaderboardError } = await supabase
        .from('leaderboard')
        .select('*')
        .not('user_id', 'is', null)
        .order('total_points', { ascending: false });

      if (leaderboardError) throw leaderboardError;

      const userIds = leaderboardData.map(entry => entry.user_id).filter(id => id !== null);
      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('id, avatar_url')
        .in('id', userIds);

      if (userError) throw userError;

      const avatarMap = new Map(userData.map(user => [user.id, user.avatar_url]));

      const formattedData = leaderboardData.map((entry, index) => ({
        rank: index + 1,
        username: entry.username,
        entries: entry.entries,
        wins: entry.wins,
        achievements: entry.achievements,
        forumActivity: entry.forum_posts + entry.forum_comments,
        totalPoints: entry.total_points,
        avatar_url: avatarMap.get(entry.user_id) || '/placeholder.svg?height=40&width=40',
      }));

      setLeaderboardData(formattedData);

      const currentUserRank = formattedData.findIndex(entry => entry.username === user.user_metadata.username) + 1;
      setUserRank(currentUserRank > 0 ? currentUserRank : null);
    } catch (error) {
      console.error('Error fetching leaderboard data:', error);
      setError('Failed to load leaderboard data. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen bg-[#EEF0FD]">
        <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-purple-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen bg-[#EEF0FD]">
        <div className="text-red-500 text-lg">{error}</div>
      </div>
    );
  }

  return (
    <div className="p-4 md:p-8 bg-[#EEF0FD] min-h-screen flex flex-col">
      <div className="flex-shrink-0 space-y-4">
        {/* Leaderboard Header */}
        <div className="bg-white rounded-lg shadow-md border border-purple-200 p-4">
          <h1 className="text-2xl font-bold text-purple-800 text-center">Leaderboard</h1>
          {userRank && (
            <p className="text-sm text-gray-600 text-center mt-2">
              You're currently ranked {userRank} out of {leaderboardData.length} users
            </p>
          )}
        </div>

        {/* Point Allocation Section */}
        <div className="bg-white rounded-lg shadow-md overflow-hidden">
          <button
            className="flex items-center justify-between w-full p-4 text-lg font-semibold text-gray-800 hover:bg-gray-50 transition-colors duration-150"
            onClick={() => setShowAllocation(!showAllocation)}
          >
            <span>Point Allocation</span>
            {showAllocation ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
          </button>
          <div className={`transition-all duration-300 ease-in-out ${
            showAllocation ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'
          } overflow-hidden`}>
            <div className="p-4 space-y-3">
              {pointAllocations.map((allocation, index) => (
                <div key={index} className="flex items-center justify-between">
                  <div className="flex items-center space-x-3">
                    <span className={`p-2 rounded-full ${allocation.color} text-white`}>
                      {allocation.icon}
                    </span>
                    <span className="text-sm text-gray-600">{allocation.action}</span>
                  </div>
                  <span className="text-lg font-semibold text-gray-800">
                    {allocation.points} {allocation.points === 1 ? 'point' : 'points'}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Leaderboard List */}
      <div className="flex-grow overflow-y-auto mt-4">
        <div className="bg-white rounded-lg shadow-md overflow-hidden">
          {leaderboardData.map((entry, index) => (
            <div
              key={index}
              className="p-4 border-b border-gray-200 hover:bg-gray-50 transition-colors duration-150"
            >
              <div className="flex flex-col sm:flex-row items-center sm:items-start">
                {/* Rank */}
                <div className="flex-shrink-0 w-12 text-center font-bold text-base text-gray-500 mr-4">
                  {entry.rank}
                </div>

                {/* Avatar and Username */}
                <div className="flex items-center sm:items-start sm:w-1/3">
                  <img src={entry.avatar_url} alt={entry.username} className="w-12 h-12 rounded-full mr-3 flex-shrink-0" />
                  <h3 className="text-sm font-semibold text-gray-800 truncate">
                    <Link to={`/profile/${entry.username}`} className="hover:underline flex items-center">
                      {entry.username}
                      <ExternalLink size={12} className="ml-1" />
                    </Link>
                  </h3>
                </div>

                {/* User Stats */}
                <div className="flex flex-wrap sm:flex-nowrap sm:w-1/3 mt-2 sm:mt-0">
                  <span className="flex items-center text-xs text-gray-500 mr-4 mb-1 sm:mb-0">
                    <Trophy size={12} className="inline mr-1 text-yellow-500" /> {entry.wins}
                  </span>
                  <span className="flex items-center text-xs text-gray-500 mr-4 mb-1 sm:mb-0">
                    <Star size={12} className="inline mr-1 text-blue-500" /> {entry.entries}
                  </span>
                  <span className="flex items-center text-xs text-gray-500 mr-4 mb-1 sm:mb-0">
                    <Award size={12} className="inline mr-1 text-purple-500" /> {entry.achievements}
                  </span>
                  <span className="flex items-center text-xs text-gray-500">
                    <MessageSquare size={12} className="inline mr-1 text-pink-500" /> {entry.forumActivity}
                  </span>
                </div>

                {/* Total Points */}
                <div className="flex-shrink-0 text-right sm:w-1/3 mt-2 sm:mt-0">
                  <span className="text-base font-bold text-gray-800">{entry.totalPoints}</span>
                  <span className="text-xs text-gray-500 block">points</span>
                </div>
              </div>
            </div>
          ))}

          {/* If no data */}
          {leaderboardData.length === 0 && (
            <div className="p-4 text-center text-gray-500">
              No leaderboard data available.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Leaderboard;
