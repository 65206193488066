import * as React from "react"
import { Check } from "lucide-react"

import { cn } from "../lib/utils"
import { Button } from "./ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "./ui/card"

const tiers = [
  {
    name: "Free",
    price: "£0.00",
    description: "Essential features for individual users",
    features: ["Full Competition List", "Basic Access"],
  },
  {
    name: "Starter",
    price: "£0.0",
    description: "Best for active participants",
    features: ["Full Competition List", "Dashboard Tracking", "User Forums"],
    recommended: true,
  },
  {
    name: "Pro",
    price: "£0.0",
    description: "Advanced features for power users",
    features: ["Full Competition List", "Dashboard Tracking", "User Forums", "Automatic Entries"],
  },
]

export default function PricingPage() {
  return (
    <section className="py-24 bg-gray-50">
      <div className="container px-4 md:px-6 mx-auto">
        <div className="grid md:grid-cols-3 gap-6 lg:gap-8 max-w-5xl mx-auto">
          {tiers.map((tier, index) => (
            <Card
              key={tier.name}
              className={cn("flex flex-col", {
                "border-purple-600": tier.recommended,
                "shadow-md": !tier.recommended,
              })}
            >
              {tier.recommended && (
                <div className="px-3 py-1 text-sm text-white bg-purple-600 rounded-t-lg text-center font-medium">
                  Recommended
                </div>
              )}
              <CardHeader className="flex-grow pt-6 pb-4">
                <CardTitle className="text-2xl mb-1">{tier.name}</CardTitle>
                <CardDescription className="h-12 flex items-center">{tier.description}</CardDescription>
              </CardHeader>
              <CardContent className="flex-grow pt-0">
                <div className="text-4xl font-bold mb-5">{tier.price}</div>
                <ul className="space-y-2 text-sm">
                  {tier.features.map((feature) => (
                    <li key={feature} className="flex items-start">
                      <Check className="mr-2 h-5 w-5 text-purple-500 flex-shrink-0 mt-0.5" />
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>
              </CardContent>
              <CardFooter className="pt-6">
                <Button
                  className={cn("w-full", {
                    "bg-purple-600 text-white hover:bg-purple-700": tier.recommended,
                    "bg-black text-white hover:bg-gray-800": !tier.recommended,
                  })}
                >
                  Get Started
                </Button>
              </CardFooter>
            </Card>
          ))}
        </div>
      </div>
    </section>
  )
}