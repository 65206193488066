// src/components/Header.tsx

import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { supabase } from '../supabaseClient'; // Ensure this import path is correct
import navlogo from './assets/navlogo.png';
import {
  Home,
  PieChart,
  Trophy,
  MessageSquare,
  LogOut,
  Menu,
  X,
  Facebook,
  Twitter,
  Instagram,
  Linkedin
} from 'lucide-react'; // Importing lucide-react icons

interface HeaderProps {
  isLoggedIn: boolean | null;
  onLogout: () => void;
  onNavigation: (path: string) => void;
}

interface BlogPost {
  id: string;
  title: string;
  slug: string;
}

const Header: React.FC<HeaderProps> = ({ isLoggedIn, onLogout, onNavigation }) => {
  const location = useLocation();
  const [compingGuides, setCompingGuides] = useState<BlogPost[]>([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    fetchCompingGuides();
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isMenuOpen]);

  const fetchCompingGuides = async () => {
    const { data, error } = await supabase
      .from('blog_posts')
      .select('id, title, slug')
      .eq('category_id', 'a4d7c00a-60b2-4939-8226-2e756f6a45a1')
      .order('created_at', { ascending: false });

    if (error) {
      console.error('Error fetching comping guides:', error);
    } else {
      setCompingGuides(data || []);
    }
  };

  const handleClick = (path: string) => (e: React.MouseEvent) => {
    // Removed e.preventDefault() to allow normal navigation
    onNavigation(path);
    // Do not close the menu automatically
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setIsMenuOpen(false);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const navItems = [
    { icon: Home, label: 'Home', to: '/' },
    { icon: PieChart, label: 'Pricing', to: '/#pricing' },
    { icon: MessageSquare, label: 'FAQs', to: '/#faq' },
    { icon: Trophy, label: 'Blog', to: '/blog' },
    // "Add Competition" has been removed
    // Add more items as needed
  ];

  // Footer items for mobile menu
  const footerItems = [
    { label: 'About Us', to: '/about-us' },
    { label: 'Terms of Service', to: '/terms-of-service' },
    { label: 'Privacy Policy', to: '/privacy-policy' },
    { label: 'Cookie Policy', to: '/cookie-policy' },
    { label: 'Contact Us', to: '/contact' },
  ];

  return (
    <header className="bg-gray-900 text-white">
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        <div className="flex items-center">
          <Link to="/">
            <img src={navlogo} alt="Sweepzy.io" className="h-12" />
          </Link>
        </div>

        {/* Hamburger menu for mobile */}
        <div className="lg:hidden">
          <button
            onClick={toggleMenu}
            className="w-10 h-10 flex items-center justify-center bg-purple-600 rounded-full focus:outline-none"
            aria-label="Toggle menu"
          >
            {isMenuOpen ? (
              <X className="w-6 h-6 text-white" />
            ) : (
              <Menu className="w-6 h-6 text-white" />
            )}
          </button>
        </div>

        {/* Navigation for desktop */}
        <nav className="hidden lg:block">
          <ul className="flex space-x-10 font-sans">
            {navItems.map((item) => (
              <NavItem
                key={item.label}
                icon={item.icon}
                label={item.label}
                to={item.to}
                isActive={
                  location.pathname === item.to ||
                  (item.to.includes('#') && location.hash === item.to.split('#')[1])
                }
              />
            ))}
          </ul>
        </nav>

        {/* Authentication buttons for desktop */}
        <div className="hidden lg:flex space-x-4">
          {isLoggedIn === true ? (
            <>
              <Link
                to="/dashboard"
                className="font-sans text-base text-white bg-purple-600 px-6 py-3 rounded hover:bg-purple-700 transition-colors"
              >
                Dashboard
              </Link>
              <button
                onClick={onLogout}
                className="font-sans text-base text-purple-600 bg-white px-6 py-3 rounded border border-purple-600 hover:bg-purple-600 hover:text-white transition-colors"
              >
                Logout
              </button>
            </>
          ) : isLoggedIn === false ? (
            <>
              <Link
                to="/login"
                className="font-sans text-base text-purple-600 bg-white px-6 py-3 rounded border border-purple-600 hover:bg-purple-600 hover:text-white transition-colors"
              >
                Login
              </Link>
              <Link
                to="/signup"
                className="font-sans text-base text-white bg-purple-600 px-6 py-3 rounded hover:bg-purple-700 transition-colors"
              >
                Sign Up
              </Link>
            </>
          ) : null}
        </div>
      </div>

      {/* Mobile menu */}
      <div
        ref={menuRef}
        className={`fixed top-0 right-0 h-full w-64 bg-gray-900 text-white transform ${
          isMenuOpen ? 'translate-x-0' : 'translate-x-full'
        } transition-transform duration-300 ease-in-out z-50 flex flex-col`}
      >
        <div className="flex items-center justify-between p-4 border-b border-gray-700">
          <Link to="/">
            <img src={navlogo} alt="Sweepzy.io" className="h-12" />
          </Link>
          <button
            onClick={toggleMenu}
            className="w-10 h-10 flex items-center justify-center bg-gray-800 rounded-full focus:outline-none"
            aria-label="Close menu"
          >
            <X className="w-6 h-6 text-white" />
          </button>
        </div>
        <ul className="flex-grow flex flex-col p-4 space-y-2 font-sans">
          {navItems.map((item) => (
            <NavItem
              key={item.label}
              icon={item.icon}
              label={item.label}
              to={item.to}
              isActive={
                location.pathname === item.to ||
                (item.to.includes('#') && location.hash === item.to.split('#')[1])
              }
            />
          ))}
        </ul>
        <div className="p-4 border-t border-gray-700">
          {isLoggedIn === true ? (
            <>
              <Link
                to="/dashboard"
                className="block w-full text-center py-3 font-sans text-lg text-white bg-purple-600 rounded mb-4 hover:bg-purple-700 transition-colors"
              >
                Dashboard
              </Link>
              <button
                onClick={onLogout}
                className="block w-full text-center py-3 font-sans text-lg text-purple-600 bg-white rounded border border-purple-600 hover:bg-purple-600 hover:text-white transition-colors"
              >
                Logout
              </button>
            </>
          ) : isLoggedIn === false ? (
            <>
              <Link
                to="/login"
                className="block w-full text-center py-3 font-sans text-lg text-purple-600 bg-white rounded border border-purple-600 mb-4 hover:bg-purple-600 hover:text-white transition-colors"
              >
                Login
              </Link>
              <Link
                to="/signup"
                className="block w-full text-center py-3 font-sans text-lg text-white bg-purple-600 rounded hover:bg-purple-700 transition-colors"
              >
                Sign Up
              </Link>
            </>
          ) : null}
        </div>
        {/* Social Icons */}
        <div className="p-4 border-t border-gray-700">
          <div className="flex justify-center space-x-4">
            <a
              href="https://facebook.com"
              className="hover:text-purple-300"
              aria-label="Facebook"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Facebook size={20} />
            </a>
            <a
              href="https://twitter.com"
              className="hover:text-purple-300"
              aria-label="Twitter"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Twitter size={20} />
            </a>
            <a
              href="https://instagram.com"
              className="hover:text-purple-300"
              aria-label="Instagram"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Instagram size={20} />
            </a>
            <a
              href="https://linkedin.com"
              className="hover:text-purple-300"
              aria-label="LinkedIn"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Linkedin size={20} />
            </a>
          </div>
        </div>
        {/* Footer Items */}
        <div className="mt-auto p-4 border-t border-gray-700">
          <div className="flex flex-wrap justify-center space-x-2 text-sm text-white mb-4">
            {footerItems.map((footerItem, index) => (
              <React.Fragment key={footerItem.label}>
                <Link
                  to={footerItem.to}
                  className="hover:text-purple-600 transition-colors"
                >
                  {footerItem.label}
                </Link>
                {index < footerItems.length - 1 && <span>|</span>}
              </React.Fragment>
            ))}
          </div>
          <div className="text-center text-xs text-white">
            © 2024 Sweepzy.io. All rights reserved.
          </div>
        </div>
      </div>
    </header>
  );
};

interface NavItemProps {
  icon: React.ElementType;
  label: string;
  to: string;
  isActive: boolean;
}

const NavItem: React.FC<NavItemProps> = ({ icon: Icon, label, to, isActive }) => {
  return (
    <li>
      <Link
        to={to}
        className="flex items-center p-2 rounded hover:bg-gray-800 transition-colors"
      >
        <div
          className={`p-2 rounded-full ${
            isActive ? 'bg-purple-600' : 'bg-transparent'
          } transition-colors`}
        >
          <Icon className={`w-6 h-6 ${isActive ? 'text-white' : 'text-gray-400'} transition-colors`} />
        </div>
        <span className="ml-3 text-base hover:text-purple-600 transition-colors">
          {label}
        </span>
      </Link>
    </li>
  );
};

export default Header;
