import React from 'react';

const CookiePolicy: React.FC = () => {
  return (
    <div className="flex-grow container mx-auto px-4 py-8">
      <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-md p-6 md:p-8">
        <h1 className="text-3xl font-bold text-[#171938] mb-6 text-center">Cookie Policy</h1>
        
        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-[#171938] mb-4">1. What Are Cookies</h2>
          <p className="text-gray-700 mb-4">
            Cookies are small pieces of data stored on your device (computer or mobile device) when you visit a website. They are widely used to make websites work more efficiently and provide a better user experience.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-[#171938] mb-4">2. How We Use Cookies</h2>
          <p className="text-gray-700 mb-4">
            We use cookies and similar technologies to:
          </p>
          <ul className="list-disc pl-5 text-gray-700">
            <li className="mb-2">Understand how you use our website</li>
            <li className="mb-2">Remember your preferences and settings</li>
            <li className="mb-2">Improve your experience on our site</li>
            <li>Provide personalized content and ads</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-[#171938] mb-4">3. Types of Cookies We Use</h2>
          <p className="text-gray-700 mb-4">
            We use both session cookies and persistent cookies. We use the following types of cookies:
          </p>
          <ul className="list-disc pl-5 text-gray-700">
            <li className="mb-2">Essential cookies: Necessary for the website to function properly</li>
            <li className="mb-2">Functional cookies: Remember your preferences and settings</li>
            <li className="mb-2">Analytics cookies: Help us understand how visitors interact with our website</li>
            <li>Advertising cookies: Used to deliver relevant ads and track ad campaign performance</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-[#171938] mb-4">4. Your Choices Regarding Cookies</h2>
          <p className="text-gray-700 mb-4">
            You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of this website may become inaccessible or not function properly.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold text-[#171938] mb-4">5. Changes to This Cookie Policy</h2>
          <p className="text-gray-700 mb-4">
            We may update our Cookie Policy from time to time. We will notify you of any changes by posting the new Cookie Policy on this page.
          </p>
        </section>
      </div>
    </div>
  );
};

export default CookiePolicy;