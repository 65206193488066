import React from 'react';

const AboutUs: React.FC = () => {
  return (
    <div className="flex-grow container mx-auto px-4 py-8">
      <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-md p-6 md:p-8">
        <h1 className="text-3xl font-bold text-[#171938] mb-6 text-center">About Sweepzy.io</h1>
        
        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-[#171938] mb-4">Our Mission</h2>
          <p className="text-gray-700 mb-4">
            At Sweepzy.io, our mission is to revolutionize the world of sweepstakes and competitions. We aim to provide a comprehensive platform that empowers users to discover, enter, and track competitions with ease and efficiency.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-[#171938] mb-4">Who We Are</h2>
          <p className="text-gray-700 mb-4">
            We are a team of passionate individuals who believe in the thrill of winning and the joy of participating. Our diverse team brings together expertise in technology, user experience, and the sweepstakes industry to create a platform that truly serves the needs of our community.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-[#171938] mb-4">What We Offer</h2>
          <ul className="list-disc pl-5 text-gray-700">
            <li className="mb-2">A curated list of thousands of competitions updated daily</li>
            <li className="mb-2">Advanced tools for tracking entries and managing competitions</li>
            <li className="mb-2">A vibrant community of fellow sweepstakes enthusiasts</li>
            <li className="mb-2">Educational resources to improve your chances of winning</li>
            <li>Cutting-edge technology to streamline the entry process</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold text-[#171938] mb-4">Our Commitment</h2>
          <p className="text-gray-700 mb-4">
            We are committed to providing a fair, transparent, and user-friendly platform. We continuously strive to improve our services based on user feedback and industry trends, ensuring that Sweepzy.io remains at the forefront of the sweepstakes world.
          </p>
          <p className="text-gray-700">
            Join us in our journey to make the world of competitions more accessible and exciting for everyone!
          </p>
        </section>
      </div>
    </div>
  );
};

export default AboutUs;