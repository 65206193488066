import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface Post {
  id: string;
  title: string;
  content: string;
  created_at: string;
  author_username: string;
  user_id: string;
}

interface Forum {
  id: string;
  name: string;
  description: string;
}

const ForumPage: React.FC = () => {
  const { forumId } = useParams<{ forumId: string }>();
  const [forum, setForum] = useState<Forum | null>(null);
  const [posts, setPosts] = useState<Post[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [newPostTitle, setNewPostTitle] = useState('');
  const [newPostContent, setNewPostContent] = useState('');
  const [currentUser, setCurrentUser] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCurrentUser();
    if (forumId) {
      fetchForum();
      fetchPosts();
    }
  }, [forumId]);

  const fetchCurrentUser = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    setCurrentUser(user?.id || null);
  };

  const fetchForum = async () => {
    if (!forumId) return;
    try {
      const { data, error } = await supabase
        .from('forums')
        .select('*')
        .eq('id', forumId)
        .single();

      if (error) throw error;
      setForum(data);
    } catch (error) {
      console.error('Error fetching forum:', error);
      setError('Failed to load forum details');
    }
  };

  const fetchPosts = async () => {
    if (!forumId) return;
    setIsLoading(true);
    try {
      const { data, error } = await supabase
        .from('posts')
        .select('*')
        .eq('forum_id', forumId)
        .order('created_at', { ascending: false });

      if (error) throw error;
      setPosts(data || []);
    } catch (error) {
      console.error('Error fetching posts:', error);
      setError('Failed to load posts');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreatePost = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!forumId) return;
    setError(null);

    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('User not authenticated');

      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('username')
        .eq('id', user.id)
        .single();

      if (userError) throw userError;

      const { data, error } = await supabase
        .from('posts')
        .insert({
          forum_id: forumId,
          user_id: user.id,
          title: newPostTitle,
          content: newPostContent,
          author_username: userData.username
        })
        .select()
        .single();

      if (error) throw error;

      setPosts(prevPosts => [data as Post, ...prevPosts]);
      setNewPostTitle('');
      setNewPostContent('');
      setShowModal(false);
    } catch (error: any) {
      console.error('Error creating post:', error);
      setError('Failed to create post. Please try again.');
    }
  };

  const handleDeletePost = async (postId: string) => {
    if (window.confirm('Are you sure you want to delete this post?')) {
      try {
        const { error } = await supabase
          .from('posts')
          .delete()
          .eq('id', postId);

        if (error) throw error;

        setPosts(prevPosts => prevPosts.filter(post => post.id !== postId));
      } catch (error) {
        console.error('Error deleting post:', error);
        setError('Failed to delete post. Please try again.');
      }
    }
  };

  if (isLoading) return <div className="text-center p-4">Loading...</div>;
  if (error) return <div className="text-center p-4 text-red-500">Error: {error}</div>;
  if (!forum) return <div className="text-center p-4">Forum not found</div>;

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold text-gray-800">{forum.name}</h1>
        <button 
          onClick={() => setShowModal(true)} 
          className="bg-[#9333EA] text-white px-4 py-2 rounded hover:bg-[#7B2CBF] transition-colors"
        >
          Create Post
        </button>
      </div>
      <p className="text-gray-600 mb-8">{forum.description}</p>

      <div className="space-y-4">
        {posts.map(post => (
          <div key={post.id} className="block bg-white rounded-lg shadow hover:shadow-md transition-shadow">
            <div className="p-6">
              <div className="flex justify-between items-center mb-2">
                <h2 className="text-xl font-semibold text-[#9333EA]">{post.title}</h2>
                {currentUser === post.user_id && (
                  <div>
                    <button 
                      onClick={() => navigate(`/post/${post.id}/edit`)}
                      className="text-blue-500 hover:text-blue-700 mr-2"
                    >
                      Edit
                    </button>
                    <button 
                      onClick={() => handleDeletePost(post.id)}
                      className="text-red-500 hover:text-red-700"
                    >
                      Delete
                    </button>
                  </div>
                )}
              </div>
              <div className="flex items-center text-gray-500 text-sm mb-2">
                <span>{post.author_username}</span>
                <span className="mx-2">•</span>
                <span>{new Date(post.created_at).toLocaleString()}</span>
              </div>
              <div className="text-gray-600 overflow-hidden" style={{ maxHeight: '3em', textOverflow: 'ellipsis' }} dangerouslySetInnerHTML={{ __html: post.content }} />
              <Link to={`/post/${post.id}`} className="text-[#9333EA] hover:underline mt-2 inline-block">Read more</Link>
            </div>
          </div>
        ))}
      </div>

      {showModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" onClick={() => setShowModal(false)}>
          <div className="relative top-20 mx-auto p-5 border w-full max-w-xl shadow-lg rounded-md bg-white" onClick={e => e.stopPropagation()}>
            <div className="mt-3">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Create New Post</h3>
              <form onSubmit={handleCreatePost}>
                <div className="mb-4">
                  <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">Title</label>
                  <input
                    type="text"
                    id="title"
                    value={newPostTitle}
                    onChange={(e) => setNewPostTitle(e.target.value)}
                    className="w-full border border-gray-300 rounded-md shadow-sm p-2"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="content" className="block text-sm font-medium text-gray-700 mb-1">Content</label>
                  <ReactQuill
                    value={newPostContent}
                    onChange={setNewPostContent}
                    className="h-64 mb-4"
                  />
                </div>
                <div className="flex justify-end mt-4">
                  <button
                    type="button"
                    className="mr-2 px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-[#9333EA] transition-colors"
                    onClick={() => setShowModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 text-sm font-medium text-white bg-[#9333EA] rounded-md hover:bg-[#7B2CBF] focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-[#9333EA] transition-colors"
                  >
                    Create Post
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ForumPage;