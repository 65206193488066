import React from 'react';

const BlogHero: React.FC = () => {
  return (
    <div className="bg-[#171938] text-white py-20">
      <div className="container mx-auto px-4 text-center">
        <h1 className="text-4xl md:text-5xl font-bold mb-4">Welcome to Our Comping UK Blog</h1>
        <p className="text-xl md:text-2xl">
        Dive into the exciting world of comping with our blog! Whether you're a seasoned comper or just getting started, join a community of enthusiasts who are passionate about winning prizes and sharing the latest tips, tricks, and insights. From expert advice to the latest competition updates, we've got everything you need to maximize your chances of winning. Start exploring today!
        </p>
      </div>
    </div>
  );
};

export default BlogHero;