import React, { useState } from 'react';
import { User, Bell, Lock, HelpCircle, Trash2 } from 'lucide-react';
import { supabase } from '../supabaseClient';

const Settings: React.FC = () => {
  const [activeTab, setActiveTab] = useState('profile');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [notifications, setNotifications] = useState({
    email: true,
    push: true,
    newsletter: false,
  });

  const handleSaveProfile = async () => {
    try {
      const { error } = await supabase.auth.updateUser({ 
        email: email,
        data: { username: username }
      });
      if (error) throw error;
      alert('Profile updated successfully!');
    } catch (error) {
      console.error('Error updating profile:', error);
      alert('Failed to update profile. Please try again.');
    }
  };

  const handleSaveNotifications = () => {
    console.log('Saving notification preferences:', notifications);
    alert('Notification preferences saved!');
  };

  const handleDeleteAccount = async () => {
    if (window.confirm('Are you sure you want to delete your account? This action cannot be undone.')) {
      try {
        const { error } = await supabase.auth.admin.deleteUser(
          (await supabase.auth.getUser()).data.user?.id as string
        );
        if (error) throw error;
        alert('Account deleted successfully.');
        // Redirect to home or login page after account deletion
      } catch (error) {
        console.error('Error deleting account:', error);
        alert('Failed to delete account. Please try again.');
      }
    }
  };

  return (
    <div className="w-full p-4 sm:p-6 bg-[#EEF0FD]">
      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <div className="p-4 sm:p-6">
          <h1 className="text-2xl sm:text-3xl font-bold mb-6">Settings</h1>
          
          <div className="bg-white rounded-lg shadow">
            <div className="flex flex-wrap border-b overflow-x-auto">
              <TabButton icon={<User size={20} />} label="Profile" active={activeTab === 'profile'} onClick={() => setActiveTab('profile')} />
              <TabButton icon={<Bell size={20} />} label="Notifications" active={activeTab === 'notifications'} onClick={() => setActiveTab('notifications')} />
              <TabButton icon={<Lock size={20} />} label="Security" active={activeTab === 'security'} onClick={() => setActiveTab('security')} />
              <TabButton icon={<HelpCircle size={20} />} label="Help" active={activeTab === 'help'} onClick={() => setActiveTab('help')} />
            </div>
            
            <div className="p-4 sm:p-6">
              {activeTab === 'profile' && (
                <div>
                  <h2 className="text-xl font-semibold mb-4">Profile Settings</h2>
                  <div className="mb-4">
                    <label htmlFor="username" className="block mb-2">Username</label>
                    <input
                      type="text"
                      id="username"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      className="w-full p-2 border rounded"
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="email" className="block mb-2">Email</label>
                    <input
                      type="email"
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="w-full p-2 border rounded"
                    />
                  </div>
                  <button onClick={handleSaveProfile} className="w-full sm:w-auto bg-[#171938] text-white px-4 py-2 rounded hover:bg-[#9A38F5]">
                    Save Changes
                  </button>
                </div>
              )}
              
              {activeTab === 'notifications' && (
                <div>
                  <h2 className="text-xl font-semibold mb-4">Notification Preferences</h2>
                  <div className="space-y-4">
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        checked={notifications.email}
                        onChange={(e) => setNotifications({...notifications, email: e.target.checked})}
                        className="mr-2"
                      />
                      Receive email notifications
                    </label>
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        checked={notifications.push}
                        onChange={(e) => setNotifications({...notifications, push: e.target.checked})}
                        className="mr-2"
                      />
                      Receive push notifications
                    </label>
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        checked={notifications.newsletter}
                        onChange={(e) => setNotifications({...notifications, newsletter: e.target.checked})}
                        className="mr-2"
                      />
                      Subscribe to newsletter
                    </label>
                  </div>
                  <button onClick={handleSaveNotifications} className="w-full sm:w-auto bg-[#171938] text-white px-4 py-2 rounded hover:bg-[#9A38F5] mt-4">
                    Save Preferences
                  </button>
                </div>
              )}
              
              {activeTab === 'security' && (
                <div>
                  <h2 className="text-xl font-semibold mb-4">Security Settings</h2>
                  <div className="space-y-4">
                    <button className="w-full sm:w-auto bg-[#171938] text-white px-4 py-2 rounded hover:bg-[#9A38F5]">
                      Change Password
                    </button>
                    <button className="w-full sm:w-auto bg-[#171938] text-white px-4 py-2 rounded hover:bg-[#9A38F5]">
                      Enable Two-Factor Authentication
                    </button>
                  </div>
                  <div className="mt-8">
                    <h3 className="text-lg font-semibold mb-2">Danger Zone</h3>
                    <button onClick={handleDeleteAccount} className="w-full sm:w-auto bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 flex items-center justify-center sm:justify-start">
                      <Trash2 size={20} className="mr-2" />
                      Delete Account
                    </button>
                  </div>
                </div>
              )}
              
              {activeTab === 'help' && (
                <div>
                  <h2 className="text-xl font-semibold mb-4">Help & Support</h2>
                  <p className="mb-4">If you need assistance, please check our FAQ or contact our support team.</p>
                  <div className="space-y-4 sm:space-y-0 sm:space-x-4">
                    <button className="w-full sm:w-auto bg-[#171938] text-white px-4 py-2 rounded hover:bg-[#9A38F5]">
                      View FAQ
                    </button>
                    <button className="w-full sm:w-auto bg-[#171938] text-white px-4 py-2 rounded hover:bg-[#9A38F5]">
                      Contact Support
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const TabButton: React.FC<{ icon: React.ReactNode; label: string; active: boolean; onClick: () => void }> = ({ icon, label, active, onClick }) => (
  <button
    className={`flex items-center px-4 py-2 ${active ? 'border-b-2 border-[#9A38F5] text-[#9A38F5]' : 'text-gray-600'} text-sm sm:text-base`}
    onClick={onClick}
  >
    {icon}
    <span className="ml-2">{label}</span>
  </button>
);

export default Settings;