import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import LatestPosts from './LatestPosts';
import PopularPosts from './PopularPosts';
import BlogHero from './BlogHero';

interface Author {
  name: string;
  avatar_url: string;
}

interface Category {
  id: string;
  name: string;
  slug: string;
}

interface BlogPost {
  id: string;
  title: string;
  slug: string;
  excerpt: string;
  featured_image_url: string;
  published_at: string;
  authors: Author;
  categories: Category;
  upvotes: number;
}

const BlogPage: React.FC = () => {
  const [posts, setPosts] = useState<BlogPost[]>([]);
  const [filteredPosts, setFilteredPosts] = useState<BlogPost[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const postsPerPage = 9;

  useEffect(() => {
    fetchCategories();
    fetchPosts();
  }, []);

  useEffect(() => {
    filterPosts();
  }, [selectedCategory, posts]);

  useEffect(() => {
    setTotalPages(Math.ceil(filteredPosts.length / postsPerPage));
  }, [filteredPosts]);

  const fetchCategories = async () => {
    const { data, error } = await supabase
      .from('categories')
      .select('*');
    if (error) console.error('Error fetching categories:', error);
    else setCategories(data || []);
  };

  const fetchPosts = async () => {
    const { data, error } = await supabase
      .from('blog_posts')
      .select(`
        *,
        authors (name, avatar_url),
        categories (id, name, slug)
      `)
      .order('published_at', { ascending: false });

    if (error) console.error('Error fetching posts:', error);
    else setPosts(data || []);
  };

  const filterPosts = () => {
    let filtered = posts;
    if (selectedCategory) {
      filtered = posts.filter(post => post.categories.id === selectedCategory);
    }
    setFilteredPosts(filtered);
    setCurrentPage(1);
  };

  const handleCategoryClick = (id: string | null) => {
    setSelectedCategory(id);
  };

  const getCurrentPagePosts = () => {
    const startIndex = (currentPage - 1) * postsPerPage;
    const endIndex = startIndex + postsPerPage;
    return filteredPosts.slice(startIndex, endIndex);
  };

  const renderCategoryFilters = () => (
    <div className="mb-8">
      <button
        className={`mr-2 mb-2 px-4 py-2 rounded ${
          !selectedCategory ? 'bg-purple-600 text-white' : 'bg-gray-200'
        }`}
        onClick={() => handleCategoryClick(null)}
      >
        All
      </button>
      {categories.map((category) => (
        <button
          key={category.id}
          className={`mr-2 mb-2 px-4 py-2 rounded ${
            selectedCategory === category.id ? 'bg-purple-600 text-white' : 'bg-gray-200'
          }`}
          onClick={() => handleCategoryClick(category.id)}
        >
          {category.name}
        </button>
      ))}
    </div>
  );

  const renderBlogPostCard = (post: BlogPost) => (
    <div key={post.id} className="bg-white rounded-lg shadow-md overflow-hidden flex flex-col">
      <img
        src={post.featured_image_url}
        alt={post.title}
        className="w-full h-48 object-cover"
      />
      <div className="p-6 flex-grow flex flex-col">
        <h2 className="text-xl font-semibold mb-2">{post.title}</h2>
        <div className="text-gray-600 mb-4 flex-grow">
          <ReactMarkdown>{post.excerpt}</ReactMarkdown>
        </div>
        <div className="flex items-center justify-between mt-4">
          <div className="flex items-center">
            <img
              src={post.authors.avatar_url}
              alt={post.authors.name}
              className="w-10 h-10 rounded-full mr-4"
            />
            <div>
              <p className="font-semibold">{post.authors.name}</p>
              <p className="text-gray-500 text-sm">
                {new Date(post.published_at).toLocaleDateString()}
              </p>
            </div>
          </div>
          <Link
            to={`/blog/${post.slug}`}
            className="bg-purple-600 text-white px-4 py-2 rounded hover:bg-purple-700"
          >
            Read More
          </Link>
        </div>
      </div>
    </div>
  );

  const renderPagination = () => {
    if (totalPages <= 1) return null;

    return (
      <div className="mt-8 flex justify-center">
        {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
          <button
            key={page}
            className={`mx-1 px-4 py-2 rounded ${
              currentPage === page ? 'bg-purple-600 text-white' : 'bg-gray-200'
            }`}
            onClick={() => setCurrentPage(page)}
          >
            {page}
          </button>
        ))}
      </div>
    );
  };

  return (
    <div className="bg-gray-100">
      <BlogHero />
      <div className="container mx-auto px-4 py-8">
        <div className="flex flex-wrap -mx-4">
          <div className="w-full lg:w-3/4 px-4">
            {renderCategoryFilters()}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {getCurrentPagePosts().map(renderBlogPostCard)}
            </div>
            {renderPagination()}
          </div>
          <div className="w-full lg:w-1/4 px-4 mt-8 lg:mt-0">
            <div className="sticky top-8">
              <LatestPosts />
              <PopularPosts />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPage;