// src/components/ui/toast.tsx

import React, { createContext, useContext, useState, ReactNode } from 'react';
import { X } from 'lucide-react';

interface ToastProps {
  id: number;
  variant: 'default' | 'destructive';
  title: string;
  description: string;
}

interface ToastContextProps {
  toast: (props: Omit<ToastProps, 'id'>) => void;
}

const ToastContext = createContext<ToastContextProps | undefined>(undefined);

export const ToastProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [toasts, setToasts] = useState<ToastProps[]>([]);

  const toast = (props: Omit<ToastProps, 'id'>) => {
    const id = Date.now();
    setToasts(prev => [...prev, { ...props, id }]);
    setTimeout(() => {
      setToasts(prev => prev.filter(t => t.id !== id));
    }, 3000);
  };

  const removeToast = (id: number) => {
    setToasts(prev => prev.filter(t => t.id !== id));
  };

  return (
    <ToastContext.Provider value={{ toast }}>
      {children}
      <div className="fixed bottom-4 right-4 flex flex-col space-y-2 z-50">
        {toasts.map((toast) => (
          <Toast key={toast.id} {...toast} onClose={() => removeToast(toast.id)} />
        ))}
      </div>
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};

interface IndividualToastProps extends ToastProps {
  onClose: () => void;
}

export const Toast: React.FC<IndividualToastProps> = ({ variant, title, description, onClose }) => {
  const bgColor = variant === 'default' ? 'bg-green-100' : 'bg-red-100';
  const textColor = variant === 'default' ? 'text-green-700' : 'text-red-700';

  return (
    <div className={`flex flex-col p-4 rounded-md shadow ${bgColor} ${textColor}`} role="alert">
      <div className="flex items-center justify-between">
        <strong>{title}</strong>
        <button onClick={onClose} aria-label="Close">
          <X className="w-4 h-4" />
        </button>
      </div>
      <p>{description}</p>
    </div>
  );
};

export const Toaster: React.FC = () => null; // Placeholder for compatibility