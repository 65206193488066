import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface Post {
  id: string;
  title: string;
  content: string;
  created_at: string;
  updated_at: string;
  forum_id: string;
  author_username: string;
  user_id: string;
}

interface Comment {
  id: string;
  content: string;
  created_at: string;
  updated_at: string;
  user_id: string;
  author_username?: string;
}

const PostPage: React.FC = () => {
  const { postId } = useParams<{ postId: string }>();
  const [post, setPost] = useState<Post | null>(null);
  const [comments, setComments] = useState<Comment[]>([]);
  const [newComment, setNewComment] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState('');
  const [editedContent, setEditedContent] = useState('');
  const [currentUser, setCurrentUser] = useState<string | null>(null);
  const [editingCommentId, setEditingCommentId] = useState<string | null>(null);
  const [editedCommentContent, setEditedCommentContent] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchCurrentUser();
    fetchPost();
    fetchComments();
  }, [postId]);

  const fetchCurrentUser = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    setCurrentUser(user?.id || null);
  };

  const fetchPost = async () => {
    try {
      const { data, error } = await supabase
        .from('posts')
        .select('*')
        .eq('id', postId)
        .single();

      if (error) throw error;
      setPost(data);
      setEditedTitle(data.title);
      setEditedContent(data.content);
    } catch (error) {
      console.error('Error fetching post:', error);
      setError('Failed to load post');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchComments = async () => {
    try {
      const { data, error } = await supabase
        .from('comments')
        .select('*')
        .eq('post_id', postId)
        .order('created_at', { ascending: true });

      if (error) throw error;

      const commentsWithUsernames = await Promise.all(
        data.map(async (comment) => {
          const { data: userData, error: userError } = await supabase
            .from('users')
            .select('username')
            .eq('id', comment.user_id)
            .single();

          if (userError) throw userError;
          return { ...comment, author_username: userData.username };
        })
      );

      setComments(commentsWithUsernames);
    } catch (error) {
      console.error('Error fetching comments:', error);
      setError('Failed to load comments');
    }
  };

  const handleSubmitComment = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const user = await supabase.auth.getUser();
      if (!user.data.user) throw new Error('User not authenticated');

      const { data, error } = await supabase
        .from('comments')
        .insert({
          post_id: postId,
          user_id: user.data.user.id,
          content: newComment
        })
        .select()
        .single();

      if (error) throw error;

      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('username')
        .eq('id', user.data.user.id)
        .single();

      if (userError) throw userError;

      const newCommentWithUsername = { ...data, author_username: userData.username };
      setComments(prevComments => [...prevComments, newCommentWithUsername]);
      setNewComment('');
    } catch (error) {
      console.error('Error creating comment:', error);
      setError('Failed to post comment');
    }
  };

  const handleEditPost = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const { error } = await supabase
        .from('posts')
        .update({ title: editedTitle, content: editedContent, updated_at: new Date().toISOString() })
        .eq('id', postId);

      if (error) throw error;

      setPost(prevPost => {
        if (prevPost) {
          return { ...prevPost, title: editedTitle, content: editedContent, updated_at: new Date().toISOString() };
        }
        return null;
      });
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating post:', error);
      setError('Failed to update post');
    }
  };

  const handleDeletePost = async () => {
    if (window.confirm('Are you sure you want to delete this post?')) {
      try {
        const { error } = await supabase
          .from('posts')
          .delete()
          .eq('id', postId);

        if (error) throw error;

        navigate(`/forum/${post?.forum_id}`);
      } catch (error) {
        console.error('Error deleting post:', error);
        setError('Failed to delete post');
      }
    }
  };

  const handleEditComment = async (commentId: string) => {
    const commentToEdit = comments.find(c => c.id === commentId);
    if (commentToEdit) {
      setEditingCommentId(commentId);
      setEditedCommentContent(commentToEdit.content);
    }
  };

  const handleSaveComment = async (commentId: string) => {
    try {
      const { error } = await supabase
        .from('comments')
        .update({ content: editedCommentContent, updated_at: new Date().toISOString() })
        .eq('id', commentId);

      if (error) throw error;

      setComments(prevComments =>
        prevComments.map(comment =>
          comment.id === commentId
            ? { ...comment, content: editedCommentContent, updated_at: new Date().toISOString() }
            : comment
        )
      );
      setEditingCommentId(null);
    } catch (error) {
      console.error('Error updating comment:', error);
      setError('Failed to update comment');
    }
  };

  const handleDeleteComment = async (commentId: string) => {
    if (window.confirm('Are you sure you want to delete this comment?')) {
      try {
        const { error } = await supabase
          .from('comments')
          .delete()
          .eq('id', commentId);

        if (error) throw error;

        setComments(prevComments => prevComments.filter(comment => comment.id !== commentId));
      } catch (error) {
        console.error('Error deleting comment:', error);
        setError('Failed to delete comment');
      }
    }
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!post) return <div>Post not found</div>;

  return (
    <div className="container mx-auto px-4 py-8">
      <Link to={`/forum/${post.forum_id}`} className="text-[#9333EA] hover:underline mb-4 inline-block">&larr; Back to Forum</Link>
      
      {isEditing ? (
        <form onSubmit={handleEditPost} className="bg-white p-6 rounded-lg shadow mb-8">
          <input
            type="text"
            value={editedTitle}
            onChange={(e) => setEditedTitle(e.target.value)}
            className="text-3xl font-bold mb-4 w-full border-b border-gray-300 focus:outline-none focus:border-[#9333EA]"
          />
          <ReactQuill
            value={editedContent}
            onChange={setEditedContent}
            className="mb-4"
          />
          <div className="flex justify-end">
            <button
              type="button"
              onClick={() => setIsEditing(false)}
              className="mr-2 px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 transition-colors"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-sm font-medium text-white bg-[#9333EA] rounded-md hover:bg-[#7B2CBF] transition-colors"
            >
              Save Changes
            </button>
          </div>
        </form>
      ) : (
        <div className="bg-white p-6 rounded-lg shadow mb-8">
          <div className="flex justify-between items-center mb-4">
            <h1 className="text-3xl font-bold">{post.title}</h1>
            {currentUser === post.user_id && (
              <div>
                <button 
                  onClick={() => setIsEditing(true)}
                  className="text-blue-500 hover:text-blue-700 mr-2"
                >
                  Edit
                </button>
                <button 
                  onClick={handleDeletePost}
                  className="text-red-500 hover:text-red-700"
                >
                  Delete
                </button>
              </div>
            )}
          </div>
          <div className="flex items-center text-gray-500 text-sm mb-4">
            <span>{post.author_username}</span>
            <span className="mx-2">•</span>
            <span>{new Date(post.created_at).toLocaleString()}</span>
            {post.updated_at !== post.created_at && (
              <span className="ml-2">(edited)</span>
            )}
          </div>
          <div className="text-gray-700 mb-4" dangerouslySetInnerHTML={{ __html: post.content }} />
        </div>
      )}

      <h2 className="text-2xl font-bold mb-4">Comments</h2>
      <div className="space-y-4 mb-8">
        {comments.map(comment => (
          <div key={comment.id} className="bg-gray-50 p-4 rounded-lg">
            {editingCommentId === comment.id ? (
              <div>
                <ReactQuill
                  value={editedCommentContent}
                  onChange={setEditedCommentContent}
                  className="mb-2"
                />
                <div className="flex justify-end">
                  <button
                    onClick={() => setEditingCommentId(null)}
                    className="mr-2 px-3 py-1 text-sm text-gray-600 hover:text-gray-800"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => handleSaveComment(comment.id)}
                    className="px-3 py-1 text-sm text-white bg-[#9333EA] rounded hover:bg-[#7B2CBF]"
                  >
                    Save
                  </button>
                </div>
              </div>
            ) : (
              <>
                <div className="flex items-center justify-between text-gray-500 text-sm mb-2">
                  <div>
                    <span>{comment.author_username}</span>
                    <span className="mx-2">•</span>
                    <span>{new Date(comment.created_at).toLocaleString()}</span>
                    {comment.updated_at !== comment.created_at && (
                      <span className="ml-2">(edited)</span>
                    )}
                  </div>
                  {currentUser === comment.user_id && (
                    <div>
                      <button
                        onClick={() => handleEditComment(comment.id)}
                        className="text-blue-500 hover:text-blue-700 mr-2"
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => handleDeleteComment(comment.id)}
                        className="text-red-500 hover:text-red-700"
                      >
                        Delete
                      </button>
                    </div>
                  )}
                </div>
                <div className="text-gray-700" dangerouslySetInnerHTML={{ __html: comment.content }} />
              </>
            )}
          </div>
        ))}
      </div>

      <form onSubmit={handleSubmitComment} className="bg-white p-6 rounded-lg shadow">
        <h3 className="text-xl font-semibold mb-4">Add a Comment</h3>
        <ReactQuill
          value={newComment}
          onChange={setNewComment}
          className="mb-4"
        />
        <button type="submit" className="bg-[#9333EA] text-white px-4 py-2 rounded hover:bg-[#7B2CBF] transition-colors">
          Post Comment
        </button>
      </form>
    </div>
  );
};

export default PostPage;