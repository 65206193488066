import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { Helmet } from 'react-helmet-async';
import LatestPosts from './LatestPosts';
import PopularPosts from './PopularPosts';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { Twitter, Facebook } from 'lucide-react';

interface Author {
  id: string;
  name: string;
  avatar_url: string;
  bio: string;
}

interface Category {
  id: string;
  name: string;
  slug: string;
}

interface CustomTableData {
  value?: string;
  statistic?: string;
  category?: string;
  percentage?: string;
}

interface CustomTable {
  name: string;
  data: CustomTableData[];
}

interface FAQ {
  question: string;
  answer: string;
}

interface BlogPost {
  id: string;
  title: string;
  slug: string;
  markdown_content: string;
  excerpt: string;
  featured_image_url: string;
  published_at: string;
  author: Author;
  category: Category;
  key_takeaways: string[];
  custom_tables: CustomTable[];
  faqs: FAQ[];
}

interface RelatedPost {
  id: string;
  title: string;
  slug: string;
  excerpt: string;
  published_at: string;
}

interface TableOfContentsItem {
  id: string;
  title: string;
  level: number;
}

const BlogPost: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const [post, setPost] = useState<BlogPost | null>(null);
  const [relatedPosts, setRelatedPosts] = useState<RelatedPost[]>([]);
  const [tableOfContents, setTableOfContents] = useState<TableOfContentsItem[]>([]);
  const [wordCount, setWordCount] = useState<number>(0);
  const [readingTime, setReadingTime] = useState<number>(0);

  useEffect(() => {
    fetchPost();
  }, [slug]);

  const fetchPost = async () => {
    const { data, error } = await supabase
      .from('blog_posts')
      .select(`
        *,
        author:authors (*),
        category:categories (*)
      `)
      .eq('slug', slug)
      .single();

    if (error) console.error('Error fetching post:', error);
    else {
      // Parse custom_tables JSON if it's stored as a string
      const parsedData = {
        ...data,
        custom_tables: typeof data.custom_tables === 'string' 
          ? JSON.parse(data.custom_tables) 
          : data.custom_tables
      };
      setPost(parsedData);
      fetchRelatedPosts(data.category.id);
      generateTableOfContents(data.markdown_content);
      calculateWordCountAndReadingTime(data.markdown_content);
    }
  };

  const fetchRelatedPosts = async (categoryId: string) => {
    const { data, error } = await supabase
      .from('blog_posts')
      .select(`
        id,
        title,
        slug,
        excerpt,
        published_at
      `)
      .eq('category.id', categoryId)
      .neq('slug', slug)
      .limit(3);

    if (error) console.error('Error fetching related posts:', error);
    else setRelatedPosts(data || []);
  };

  const generateTableOfContents = (content: string) => {
    const headings = content.match(/#{2,3}\s.+/g) || [];
    const toc = headings.map((heading) => {
      const level = heading.match(/#{2,3}/)?.[0].length || 2;
      const title = heading.replace(/#{2,3}\s/, '');
      const id = title.toLowerCase().replace(/\s+/g, '-');
      return { id, title, level };
    });
    setTableOfContents(toc);
  };

  const calculateWordCountAndReadingTime = (content: string) => {
    const words = content.trim().split(/\s+/).length;
    setWordCount(words);
    setReadingTime(Math.ceil(words / 200)); // Assuming average reading speed of 200 words per minute
  };

  const renderCustomTable = (tableName: string) => {
    if (!post || !post.custom_tables) return null;
    const table = post.custom_tables.find(t => t.name === tableName);
    if (!table) return null;

    const headers = Object.keys(table.data[0]);

    return (
      <div className="my-4">
        <table className="w-full border-collapse border border-gray-300">
          <thead>
            <tr>
              {headers.map((header) => (
                <th key={header} className="border border-gray-300 px-4 py-2 bg-gray-100">
                  {header.charAt(0).toUpperCase() + header.slice(1)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {table.data.map((row, index) => (
              <tr key={index}>
                {headers.map((header) => (
                  <td key={`${index}-${header}`} className="border border-gray-300 px-4 py-2">
                    {row[header as keyof CustomTableData]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const renderPostContent = (post: BlogPost) => {
    const customRenderers: Record<string, React.FC<any>> = {
      p: ({node, ...props}) => {
        const children = props.children;
        if (typeof children === 'string' && children.trim().startsWith('[TABLE:')) {
          const tableName = children.trim().match(/\[TABLE:(.*?)\]/)?.[1];
          return tableName ? renderCustomTable(tableName) : <p className="mb-4" {...props} />;
        }
        return <p className="mb-4" {...props} />;
      },
      h1: ({node, ...props}) => <h1 className="text-3xl font-bold mt-6 mb-4" {...props} />,
      h2: ({node, ...props}) => <h2 id={props.children?.toString().toLowerCase().replace(/\s+/g, '-')} className="text-2xl font-bold mt-5 mb-3" {...props} />,
      h3: ({node, ...props}) => <h3 id={props.children?.toString().toLowerCase().replace(/\s+/g, '-')} className="text-xl font-semibold mt-4 mb-2" {...props} />,
      ul: ({node, ...props}) => <ul className="list-disc pl-5 mb-4" {...props} />,
      ol: ({node, ...props}) => <ol className="list-decimal pl-5 mb-4" {...props} />,
      li: ({node, ...props}) => <li className="mb-2" {...props} />,
      blockquote: ({node, ...props}) => <blockquote className="border-l-4 border-gray-300 pl-4 italic my-4" {...props} />,
      code: ({ node, className, children, ...props }) => {
        const match = /language-(\w+)/.exec(className || '')
        return match ? (
          <pre className="bg-gray-100 rounded p-4 overflow-x-auto">
            <code className={className} {...props}>
              {children}
            </code>
          </pre>
        ) : (
          <code className={className} {...props}>
            {children}
          </code>
        )
      },
      a: ({node, ...props}) => (
        <a className="bg-green-500 text-white px-2 py-1 rounded-full text-sm inline-block" {...props} />
      )
    };

    return (
      <ReactMarkdown
        rehypePlugins={[rehypeRaw]}
        components={customRenderers}
      >
        {post.markdown_content}
      </ReactMarkdown>
    );
  };

  if (!post) return <div>Loading...</div>;

  return (
    <>
      <Helmet>
        <title>{post.title} | Sweepzy.io Blog</title>
        <meta name="description" content={post.excerpt} />
        <link rel="canonical" href={`https://sweepzy.io/blog/${post.slug}`} />
        <meta property="og:title" content={post.title} />
        <meta property="og:description" content={post.excerpt} />
        <meta property="og:image" content={post.featured_image_url} />
        <meta property="og:url" content={`https://sweepzy.io/blog/${post.slug}`} />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            "headline": post.title,
            "image": post.featured_image_url,
            "author": {
              "@type": "Person",
              "name": post.author.name
            },
            "publisher": {
              "@type": "Organization",
              "name": "Sweepzy.io",
              "logo": {
                "@type": "ImageObject",
                "url": "https://sweepzy.io/logo.png"
              }
            },
            "datePublished": post.published_at,
            "dateModified": post.published_at,
            "mainEntityOfPage": {
              "@type": "WebPage",
              "@id": `https://sweepzy.io/blog/${post.slug}`
            },
            "description": post.excerpt
          })}
        </script>
      </Helmet>

      <div className="container mx-auto px-4 py-8">
        <nav className="text-gray-500 mb-4" aria-label="Breadcrumb">
          <ol className="list-none p-0 inline-flex">
            <li className="flex items-center">
              <Link to="/" className="hover:text-gray-700">Home</Link>
              <svg className="fill-current w-3 h-3 mx-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"/></svg>
            </li>
            <li className="flex items-center">
              <Link to="/blog" className="hover:text-gray-700">Blog</Link>
              <svg className="fill-current w-3 h-3 mx-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"/></svg>
            </li>
            <li>
              <span className="text-gray-700" aria-current="page">{post.title}</span>
            </li>
          </ol>
        </nav>

        <div className="flex flex-wrap -mx-4">
          <div className="w-full lg:w-2/3 px-4">
            <h1 className="text-4xl font-bold mb-4">{post.title}</h1>
            <div className="mb-4">
              <span className="bg-[#9333EA] text-white px-3 py-1 rounded-full text-sm">
                {wordCount} words · {readingTime} min read
              </span>
            </div>
            <div className="mb-8 text-center">
              <img
                src={post.featured_image_url}
                alt={post.title}
                className="max-w-full h-auto mx-auto rounded-lg"
                style={{ maxHeight: '400px', objectFit: 'contain' }}
              />
            </div>
            <div className="flex items-center mb-4">
              <img
                src={post.author.avatar_url}
                alt={post.author.name}
                className="w-10 h-10 rounded-full mr-4"
              />
              <div>
                <p className="font-semibold">{post.author.name}</p>
                <p className="text-gray-500 text-sm">
                  {new Date(post.published_at).toLocaleDateString()} · {post.category.name}
                </p>
              </div>
            </div>
            <div className="mb-8 bg-blue-50 rounded-lg p-6">
              <h2 className="text-2xl font-bold mb-2">Article Summary</h2>
              <p className="text-gray-700">{post.excerpt}</p>
            </div>
            <div className="mb-8">
              <h2 className="text-2xl font-bold mb-2">Contents</h2>
              <ul className="list-none pl-0">
                {tableOfContents.map((item) => (
                  <li key={item.id} className={`mb-1 ${item.level === 3 ? 'ml-4' : ''}`}>
                    <a href={`#${item.id}`} className="text-blue-600 hover:underline">
                      {item.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <article className="prose max-w-none mb-8">
              {renderPostContent(post)}
            </article>
            {post.key_takeaways && post.key_takeaways.length > 0 && (
              <div className="mb-8 bg-[#EEF0FD] rounded-lg p-6">
                <h2 className="text-2xl font-bold mb-2">Key Takeaways</h2>
                <ul className="list-disc pl-5">
                  {post.key_takeaways.map((takeaway, index) => (
                    <li key={index} className="mb-2">{takeaway}</li>
                  ))}
                </ul>
              </div>
            )}
            {post.faqs && post.faqs.length > 0 && (
              <div className="mb-8 bg-yellow-50 rounded-lg p-6">
                <h2 className="text-2xl font-bold mb-4">Frequently Asked Questions</h2>
                {post.faqs.map((faq, index) => (
                  <div key={index} className="mb-4">
                    <h3 className="text-lg font-semibold mb-2">{faq.question}</h3>
                    <p className="text-gray-700">{faq.answer}</p>
                  </div>
                ))}
              </div>
            )}
            <div className="mb-8 bg-green-50 rounded-lg p-6">
              <h2 className="text-2xl font-bold mb-2">Ready to start comping?</h2>
              <p className="mb-4">Join Sweepzy today and increase your chances of winning amazing prizes!</p>
              <a href="/signup" className="bg-green-500 text-white px-6 py-3 rounded-lg font-semibold hover:bg-green-600 transition duration-300">
                Sign Up Now
              </a>
            </div>
            <div className="flex justify-center items-center mb-8">
              <span className="mr-2">Share on:</span>
              <a href={`https://twitter.com/intent/tweet?url=https://sweepzy.io/blog/${post.slug}&text=${post.title}`} target="_blank" rel="noopener noreferrer" className="mx-2">
                <Twitter size={24} className="text-blue-400 hover:text-blue-500" />
              </a>
              <a href={`https://www.facebook.com/sharer/sharer.php?u=https://sweepzy.io/blog/${post.slug}`} target="_blank" rel="noopener noreferrer" className="mx-2">
                <Facebook size={24} className="text-blue-600 hover:text-blue-700" />
              </a>
            </div>
            <div className="bg-gray-100 p-6 rounded-lg mb-8">
              <h2 className="text-2xl font-bold mb-4">About the Author</h2>
              <div className="flex items-center">
                <img
                  src={post.author.avatar_url}
                  alt={post.author.name}
                  className="w-16 h-16 rounded-full mr-6"
                />
                <div>
                  <p className="font-semibold text-lg">{post.author.name}</p>
                  <p className="text-gray-600">{post.author.bio}</p>
                </div>
              </div>
            </div>
            {relatedPosts.length > 0 && (
              <div>
                <h2 className="text-2xl font-bold mb-4">Related Posts</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                  {relatedPosts.map((relatedPost) => (
                    <Link key={relatedPost.id} to={`/blog/${relatedPost.slug}`} className="block hover:bg-gray-100 p-4 rounded">
                      <h3 className="font-semibold mb-2">{relatedPost.title}</h3>
                      <p className="text-sm text-gray-500 mb-2">{relatedPost.excerpt}</p>
                      <p className="text-xs text-gray-400">{new Date(relatedPost.published_at).toLocaleDateString()}</p>
                    </Link>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className="w-full lg:w-1/3 px-4 mt-8 lg:mt-0">
            <div className="sticky top-8">
              <LatestPosts />
              <PopularPosts />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogPost;