import React from 'react';
import { ArrowRight } from 'lucide-react';
import heroImage from './assets/heroimage.webp';  // Using hero image as placeholder

const Analysis: React.FC = () => (
  <section className="bg-purple-100 py-16 sm:py-24">
    <div className="container mx-auto px-4">
      <div className="flex flex-col lg:flex-row items-center justify-between">
        <div className="w-full lg:w-1/2 mb-8 lg:mb-0 lg:pr-12">
          <h2 className="text-3xl sm:text-4xl font-bold mb-4 sm:mb-6">Effortlessly analyze your performance</h2>
          <p className="text-base sm:text-xl mb-6 sm:mb-8">Maximize your chances of winning with our expert comping & sweepstaking platform. Analyze your entries and stats, and join the community of successful compers. Don't settle for mediocre tools — join the biggest community and start winning today!</p>
          <button className="bg-purple-600 text-white px-4 py-2 sm:px-6 sm:py-3 rounded-lg flex items-center hover:bg-purple-700 transition-colors text-sm sm:text-base">
            Discover More <ArrowRight className="ml-2" size={20} />
          </button>
        </div>
        <div className="w-full lg:w-5/12">
          <img src={heroImage} alt="Analysis illustration" className="w-full rounded-lg shadow-lg" />
        </div>
      </div>
    </div>
  </section>
);

export default Analysis;