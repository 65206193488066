import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from '../supabaseClient';

interface BlogPost {
  id: string;
  title: string;
  slug: string;
  upvotes: number;
}

const PopularPosts: React.FC = () => {
  const [popularPosts, setPopularPosts] = useState<BlogPost[]>([]);

  useEffect(() => {
    fetchPopularPosts();
  }, []);

  const fetchPopularPosts = async () => {
    const { data, error } = await supabase
      .from('blog_posts')
      .select('id, title, slug, upvotes')
      .order('upvotes', { ascending: false })
      .limit(5);

    if (error) console.error('Error fetching popular posts:', error);
    else setPopularPosts(data || []);
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <h2 className="text-2xl font-bold mb-4">Popular Now</h2>
      <ul className="space-y-4">
        {popularPosts.map((post) => (
          <li key={post.id}>
            <Link to={`/blog/${post.slug}`} className="block hover:bg-gray-100 p-2 rounded">
              <h3 className="font-semibold">{post.title}</h3>
              <p className="text-sm text-gray-500">
                {post.upvotes} upvotes
              </p>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PopularPosts;