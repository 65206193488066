import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import { Mail, Edit2, Save, X, ArrowUp, Trash2, Trophy, Calendar } from 'lucide-react';

interface Badge {
  id: string;
  name: string;
  icon: string;
  description: string;
}

interface ActivityItem {
  id: string;
  type: 'competition_entry' | 'competition_win' | 'achievement_earned' | 'forum_post';
  description: string;
  timestamp: string;
}

interface Win {
  id: string;
  title: string;
  date: string;
}

const Profile: React.FC = () => {
  const [user, setUser] = useState<any>(null);
  const [badges, setBadges] = useState<Badge[]>([]);
  const [recentActivity, setRecentActivity] = useState<ActivityItem[]>([]);
  const [wins, setWins] = useState<Win[]>([]);
  const [isEditingBio, setIsEditingBio] = useState(false);
  const [editedBio, setEditedBio] = useState('');
  const [selectedBadge, setSelectedBadge] = useState<Badge | null>(null);

  useEffect(() => {
    fetchUserProfile();
    fetchBadges();
    fetchRecentActivity();
    fetchWins();
  }, []);

  const fetchUserProfile = async () => {
    const { data: { user: authUser } } = await supabase.auth.getUser();
    if (authUser) {
      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('id', authUser.id)
        .single();

      if (error) {
        console.error('Error fetching user profile:', error);
      } else {
        setUser(data);
        setEditedBio(data.bio || '');
      }
    }
  };

  const fetchBadges = async () => {
    const { data: { user: authUser } } = await supabase.auth.getUser();
    if (authUser) {
      const { data, error } = await supabase
        .from('user_achievements')
        .select('achievement_name, achievements(icon, description)')
        .eq('user_id', authUser.id);

      if (error) {
        console.error('Error fetching badges:', error);
      } else {
        const formattedBadges = data.map((item: any) => ({
          id: item.achievement_name,
          name: item.achievement_name,
          icon: item.achievements.icon,
          description: item.achievements.description
        }));
        setBadges(formattedBadges);
      }
    }
  };

  const fetchRecentActivity = async () => {
    const { data: { user: authUser } } = await supabase.auth.getUser();
    if (authUser) {
      const { data, error } = await supabase
        .from('user_activity')
        .select('*')
        .eq('user_id', authUser.id)
        .order('timestamp', { ascending: false })
        .limit(5);

      if (error) {
        console.error('Error fetching recent activity:', error);
      } else {
        setRecentActivity(data);
      }
    }
  };

  const fetchWins = async () => {
    const { data: { user: authUser } } = await supabase.auth.getUser();
    if (authUser) {
      const { data, error } = await supabase
        .from('user_competition_status')
        .select('id, competitions(title, closing_date)')
        .eq('user_id', authUser.id)
        .eq('won', true)
        .order('competitions(closing_date)', { ascending: false });

      if (error) {
        console.error('Error fetching wins:', error);
      } else {
        setWins(data.map((item: any) => ({
          id: item.id,
          title: item.competitions.title,
          date: item.competitions.closing_date
        })));
      }
    }
  };

  const handleSaveBio = async () => {
    const { data: { user: authUser } } = await supabase.auth.getUser();
    if (authUser) {
      const { error } = await supabase
        .from('users')
        .update({ bio: editedBio })
        .eq('id', authUser.id);

      if (error) {
        console.error('Error updating user bio:', error);
      } else {
        setUser({ ...user, bio: editedBio });
        setIsEditingBio(false);
      }
    }
  };

  const handleAvatarChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { data: { user: authUser } } = await supabase.auth.getUser();
    if (authUser && e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const fileName = `${authUser.id}/${Date.now()}_${file.name}`;
      const { data: uploadData, error: uploadError } = await supabase.storage
        .from('profile-photos')
        .upload(fileName, file);

      if (uploadError) {
        console.error('Error uploading avatar:', uploadError);
        return;
      }

      const { data: { publicUrl } } = supabase.storage
        .from('profile-photos')
        .getPublicUrl(fileName);

      const { error: updateError } = await supabase
        .from('users')
        .update({ avatar_url: publicUrl })
        .eq('id', authUser.id);

      if (updateError) {
        console.error('Error updating user profile:', updateError);
      } else {
        setUser({ ...user, avatar_url: publicUrl });
      }
    }
  };

  const handleDeleteAvatar = async () => {
    const { data: { user: authUser } } = await supabase.auth.getUser();
    if (!authUser) return;

    const { data: userProfile, error: fetchError } = await supabase
      .from('users')
      .select('avatar_url')
      .eq('id', authUser.id)
      .single();

    if (fetchError) {
      console.error('Error fetching user profile:', fetchError);
      return;
    }

    if (userProfile && userProfile.avatar_url) {
      const fileName = userProfile.avatar_url.split('/').pop();
      if (!fileName) {
        console.error('Invalid avatar URL');
        return;
      }

      const { error: deleteError } = await supabase.storage
        .from('profile-photos')
        .remove([`${authUser.id}/${fileName}`]);

      if (deleteError) {
        console.error('Error deleting avatar:', deleteError);
        return;
      }

      const { error: updateError } = await supabase
        .from('users')
        .update({ avatar_url: null })
        .eq('id', authUser.id);

      if (updateError) {
        console.error('Error updating user profile:', updateError);
      } else {
        setUser({ ...user, avatar_url: null });
      }
    }
  };

  const BadgeModal: React.FC<{ badge: Badge, onClose: () => void }> = ({ badge, onClose }) => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg p-6 max-w-sm w-full">
        <div className="flex items-center justify-center space-x-2 mb-4">
          <span className="text-4xl">{badge.icon}</span>
        </div>
        <h2 className="text-xl font-bold text-center mb-2">{badge.name}</h2>
        <p className="text-gray-600 text-center mb-4">{badge.description}</p>
        <button 
          className="w-full bg-purple-600 text-white py-2 rounded-lg hover:bg-purple-700 transition-colors"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );

  return (
    <div className="w-full max-w-full overflow-x-hidden bg-[#EEF0FD] p-4">
      <div className="bg-white shadow-md rounded-lg overflow-hidden max-w-3xl mx-auto">
        <div className="p-4 sm:p-6">
          <h1 className="text-2xl sm:text-3xl font-bold text-[#171938] mb-6 text-center">My Profile</h1>

          <div className="flex flex-col items-center mb-6">
            <img
              src={user?.avatar_url || 'https://via.placeholder.com/150'}
              alt="Profile"
              className="w-24 h-24 sm:w-32 sm:h-32 rounded-full mb-4"
            />
            <div className="mb-4 flex justify-center">
              <input
                type="file"
                onChange={handleAvatarChange}
                accept="image/*"
                className="hidden"
                id="avatar-upload"
              />
              <label htmlFor="avatar-upload" className="cursor-pointer bg-[#9A38F5] text-white px-3 py-2 rounded hover:bg-[#7929D2] inline-flex items-center mr-2">
                <ArrowUp size={16} />
              </label>
              {user?.avatar_url && (
                <button onClick={handleDeleteAvatar} className="bg-red-500 text-white px-3 py-2 rounded hover:bg-red-600">
                  <Trash2 size={16} />
                </button>
              )}
            </div>
            <h2 className="text-lg sm:text-xl font-semibold mb-2">{user?.username}</h2>
            <p className="text-gray-600 mb-4 flex items-center justify-center text-sm">
              <Mail size={14} className="mr-1" />
              {user?.email}
            </p>
          </div>

          <div className="mb-6">
            <h3 className="text-lg sm:text-xl font-semibold mb-2">Bio</h3>
            {isEditingBio ? (
              <>
                <textarea
                  value={editedBio}
                  onChange={(e) => setEditedBio(e.target.value)}
                  className="w-full p-2 border rounded mb-4 text-sm"
                  rows={4}
                />
                <div className="flex justify-end">
                  <button onClick={handleSaveBio} className="mr-2 bg-[#9A38F5] text-white px-3 py-1 rounded hover:bg-[#7929D2] text-sm">
                    <Save size={14} className="inline mr-1" /> Save
                  </button>
                  <button onClick={() => setIsEditingBio(false)} className="bg-gray-300 text-gray-700 px-3 py-1 rounded hover:bg-gray-400 text-sm">
                    <X size={14} className="inline mr-1" /> Cancel
                  </button>
                </div>
              </>
            ) : (
              <>
                <p className="text-gray-700 mb-4 text-sm">{user?.bio}</p>
                <button onClick={() => setIsEditingBio(true)} className="bg-[#9A38F5] text-white px-3 py-1 rounded hover:bg-[#7929D2] text-sm">
                  <Edit2 size={14} className="inline mr-1" /> Edit
                </button>
              </>
            )}
          </div>

          <div className="mb-6">
            <h3 className="text-xl font-semibold mb-4">Badges</h3>
            <div className="grid grid-cols-4 gap-2">
              {badges.map((badge) => (
                <div 
                  key={badge.id} 
                  className="bg-white rounded-lg shadow-md p-2 flex flex-col items-center justify-center aspect-square cursor-pointer hover:shadow-lg transition-shadow"
                  onClick={() => setSelectedBadge(badge)}
                >
                  <span className="text-2xl mb-1">{badge.icon}</span>
                  <span className="text-xs text-center truncate w-full">{badge.name}</span>
                </div>
              ))}
            </div>
          </div>

          <div className="mb-6">
            <h3 className="text-xl font-semibold mb-4">Recent Activity</h3>
            <div className="space-y-2">
              {recentActivity.map((activity) => (
                <div key={activity.id} className="bg-gray-100 p-3 rounded-lg">
                  <p className="text-gray-700 mb-1 text-sm">{activity.description}</p>
                  <div className="flex items-center text-xs text-gray-500">
                    <Calendar size={12} className="mr-1" />
                    {new Date(activity.timestamp).toLocaleDateString()}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div>
            <h3 className="text-xl font-semibold mb-4">Wins</h3>
            <div className="space-y-2">
              {wins.map((win) => (
                <div key={win.id} className="bg-green-100 p-3 rounded-lg">
                  <div className="flex items-start">
                    <Trophy size={16} className="mr-2 text-yellow-500 flex-shrink-0 mt-1" />
                    <div>
                      <p className="text-gray-800 font-semibold text-sm">{win.title}</p>
                      <div className="flex items-center text-xs text-gray-500 mt-1">
                        <Calendar size={12} className="mr-1" />
                        {new Date(win.date).toLocaleDateString()}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {selectedBadge && (
        <BadgeModal 
          badge={selectedBadge} 
          onClose={() => setSelectedBadge(null)} 
        />
      )}
    </div>
  );
};

export default Profile;