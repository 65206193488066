import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { User, Trophy, Calendar } from 'lucide-react';
import Layout from './Layout';

interface Badge {
  id: string;
  name: string;
  icon: string;
}

interface ActivityItem {
  id: string;
  type: 'competition_entry' | 'competition_win' | 'achievement_earned' | 'forum_post';
  description: string;
  timestamp: string;
}

interface Win {
  id: string;
  title: string;
  date: string;
}

const PublicProfile: React.FC = () => {
  const { username } = useParams<{ username: string }>();
  const [user, setUser] = useState<any>(null);
  const [badges, setBadges] = useState<Badge[]>([]);
  const [recentActivity, setRecentActivity] = useState<ActivityItem[]>([]);
  const [wins, setWins] = useState<Win[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchUserProfile();
  }, [username]);

  const fetchUserProfile = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('username', username)
        .single();

      if (error) throw error;

      setUser(data);
      await Promise.all([
        fetchBadges(data.id),
        fetchRecentActivity(data.id),
        fetchWins(data.id)
      ]);
    } catch (error) {
      console.error('Error fetching user profile:', error);
      setError('Failed to load user profile');
    } finally {
      setLoading(false);
    }
  };

  const fetchBadges = async (userId: string) => {
    try {
      const { data, error } = await supabase
        .from('user_achievements')
        .select(`
          achievement_name,
          achievements (
            name,
            icon
          )
        `)
        .eq('user_id', userId);

      if (error) throw error;

      if (data && data.length > 0) {
        const formattedBadges = data.map((item: any) => ({
          id: item.achievement_name,
          name: item.achievements.name,
          icon: item.achievements.icon
        }));
        setBadges(formattedBadges);
      } else {
        setBadges([]);
      }
    } catch (error) {
      console.error('Error fetching badges:', error);
      setBadges([]);
    }
  };

  const fetchRecentActivity = async (userId: string) => {
    try {
      const { data, error } = await supabase
        .from('user_activity')
        .select('*')
        .eq('user_id', userId)
        .order('timestamp', { ascending: false })
        .limit(5);

      if (error) throw error;
      setRecentActivity(data || []);
    } catch (error) {
      console.error('Error fetching recent activity:', error);
      setRecentActivity([]);
    }
  };

  const fetchWins = async (userId: string) => {
    try {
      const { data, error } = await supabase
        .from('user_competition_status')
        .select('id, competitions(title, closing_date)')
        .eq('user_id', userId)
        .eq('won', true)
        .order('competitions(closing_date)', { ascending: false });

      if (error) throw error;
      
      const formattedWins = data?.map((item: any) => ({
        id: item.id,
        title: item.competitions.title,
        date: item.competitions.closing_date
      })) || [];
      
      setWins(formattedWins);
    } catch (error) {
      console.error('Error fetching wins:', error);
      setWins([]);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!user) return <div>User not found</div>;

  return (
    <Layout>
      <div className="container mx-auto px-4 py-8">
        <div className="bg-white shadow-md rounded-lg overflow-hidden">
          <div className="p-6">
            <h1 className="text-3xl font-bold text-[#171938] mb-6">{user.username}'s Profile</h1>

            <div className="flex flex-col md:flex-row">
              <div className="md:w-1/3 mb-6 md:mb-0">
                <div className="text-center">
                  <img
                    src={user.avatar_url || 'https://via.placeholder.com/150'}
                    alt="Profile"
                    className="w-32 h-32 rounded-full mx-auto mb-4"
                  />
                  <h2 className="text-xl font-semibold mb-2">{user.username}</h2>
                  <p className="text-gray-600 mb-4 flex items-center justify-center">
                    <User size={16} className="mr-2" />
                    Member since {new Date(user.created_at).getFullYear()}
                  </p>
                </div>
              </div>

              <div className="md:w-2/3 md:pl-6">
                <div className="border border-gray-200 rounded-lg p-4 mb-6">
                  <h3 className="text-xl font-semibold mb-2">Bio</h3>
                  <p className="text-gray-700">{user.bio || 'No bio available'}</p>
                </div>
              </div>
            </div>

            <div className="mt-8">
              <h3 className="text-xl font-semibold mb-4">Badges</h3>
              <div className="flex flex-wrap gap-4">
                {badges.length > 0 ? (
                  badges.map((badge) => (
                    <div key={badge.id} className="bg-[#EEF0FD] text-[#171938] p-3 rounded-lg text-center">
                      <span className="text-2xl mb-2 block">{badge.icon}</span>
                      <span className="text-sm">{badge.name}</span>
                    </div>
                  ))
                ) : (
                  <p>No badges earned yet</p>
                )}
              </div>
            </div>

            <div className="flex flex-col md:flex-row mt-8">
              <div className="md:w-2/3 pr-4">
                <h3 className="text-xl font-semibold mb-4">Recent Activity</h3>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  {recentActivity.length > 0 ? (
                    recentActivity.map((activity) => (
                      <div key={activity.id} className="bg-gray-100 p-4 rounded-lg">
                        <p className="text-gray-700 mb-2">{activity.description}</p>
                        <div className="flex items-center text-sm text-gray-500">
                          <Calendar size={14} className="mr-1" />
                          {new Date(activity.timestamp).toLocaleDateString()}
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No recent activity</p>
                  )}
                </div>
              </div>

              <div className="md:w-1/3 mt-6 md:mt-0">
                <h3 className="text-xl font-semibold mb-4">Wins</h3>
                <div className="space-y-4">
                  {wins.length > 0 ? (
                    wins.map((win) => (
                      <div key={win.id} className="bg-green-100 p-4 rounded-lg">
                        <div className="flex items-start">
                          <Trophy size={20} className="mr-2 text-yellow-500 flex-shrink-0 mt-1" />
                          <div>
                            <p className="text-gray-800 font-semibold">{win.title}</p>
                            <div className="flex items-center text-sm text-gray-500 mt-1">
                              <Calendar size={14} className="mr-1" />
                              {new Date(win.date).toLocaleDateString()}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No wins yet</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PublicProfile;