import { logUserActivity } from './activityTracker';

const TIME_TRACKING_KEY = 'timeTrackingData';

export const timeTrackingUtility = {
  startTracking: (activityType: string) => {
    const startTime = Date.now();
    localStorage.setItem(`${TIME_TRACKING_KEY}_${activityType}`, startTime.toString());
  },

  stopTracking: (activityType: string, userId: string, username: string) => {
    const startTimeStr = localStorage.getItem(`${TIME_TRACKING_KEY}_${activityType}`);
    if (startTimeStr) {
      const startTime = parseInt(startTimeStr, 10);
      const endTime = Date.now();
      const timeSpentSeconds = Math.round((endTime - startTime) / 1000);

      localStorage.removeItem(`${TIME_TRACKING_KEY}_${activityType}`);

      // Log the activity
      logUserActivity(userId, username, {
        action_type: 'time_spent',
        details: {
          activity_type: activityType,
          time_spent_seconds: timeSpentSeconds
        }
      });
    }
  },

  resetTracking: () => {
    Object.keys(localStorage).forEach(key => {
      if (key.startsWith(TIME_TRACKING_KEY)) {
        localStorage.removeItem(key);
      }
    });
  }
};