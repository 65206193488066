import { logUserActivity } from './activityTracker';

export function trackDeviceInfo(userId: string, username: string) {
  const userAgent = navigator.userAgent.toLowerCase();
  const screenSize = `${window.screen.width}x${window.screen.height}`;

  // Improved device type detection
  let deviceType = 'desktop';
  if (/mobile|iphone|ipad|ipod|android|blackberry|mini|windows\sce|palm/i.test(userAgent)) {
    deviceType = 'mobile';
    if (/ipad/i.test(userAgent)) {
      deviceType = 'tablet';
    }
  }

  // Improved browser detection
  let browser = 'unknown';
  if (userAgent.indexOf('firefox') > -1) {
    browser = 'firefox';
  } else if (userAgent.indexOf('chrome') > -1) {
    browser = 'chrome';
  } else if (userAgent.indexOf('safari') > -1) {
    browser = 'safari';
  } else if (userAgent.indexOf('opera') > -1 || userAgent.indexOf('opr') > -1) {
    browser = 'opera';
  } else if (userAgent.indexOf('edge') > -1) {
    browser = 'edge';
  } else if (userAgent.indexOf('trident') > -1) {
    browser = 'ie';
  }

  // Improved operating system detection
  let os = 'unknown';
  if (/win/i.test(userAgent)) {
    os = 'windows';
  } else if (/mac/i.test(userAgent)) {
    os = 'mac';
  } else if (/linux/i.test(userAgent)) {
    os = 'linux';
  } else if (/android/i.test(userAgent)) {
    os = 'android';
  } else if (/iphone|ipad|ipod/i.test(userAgent)) {
    os = 'ios';
  }

  logUserActivity(userId, username, {
    action_type: 'device_info',
    details: {
      user_agent: userAgent,
      screen_size: screenSize,
      device_type: deviceType,
      browser: browser,
      operating_system: os
    }
  });
}