import React, { useState } from 'react';
import { Mail, Send } from 'lucide-react';
import { useToast } from './ui/toast';
import { supabase } from '../supabaseClient'; // Make sure this path is correct

const Contact: React.FC = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { toast } = useToast();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const { data, error } = await supabase
        .from('contact_form_submissions')
        .insert([
          { name, email, message }
        ]);

      if (error) throw error;

      // Clear form fields
      setName('');
      setEmail('');
      setMessage('');

      toast({
        variant: 'default',
        title: 'Message Sent',
        description: "Thank you for your message. We'll get back to you soon!",
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'There was a problem sending your message. Please try again.',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="flex-grow container mx-auto px-4 py-8">
      <div className="max-w-2xl mx-auto bg-white rounded-lg shadow-md p-6 md:p-8">
        <h1 className="text-3xl font-bold text-[#171938] mb-6 text-center">Contact Us</h1>
        <p className="text-gray-600 mb-6 text-center">
          Have a question or feedback? We'd love to hear from you!
        </p>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
              Name
            </label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-[#9A38F5] focus:border-[#9A38F5]"
              required
            />
          </div>
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
              Email
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-[#9A38F5] focus:border-[#9A38F5]"
              required
            />
          </div>
          <div>
            <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">
              Message
            </label>
            <textarea
              id="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              rows={4}
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-[#9A38F5] focus:border-[#9A38F5]"
              required
            ></textarea>
          </div>
          <button
            type="submit"
            className={`w-full flex items-center justify-center bg-[#9A38F5] text-white py-2 px-4 rounded-md hover:bg-[#7B2CBF] transition-colors ${
              isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              'Sending...'
            ) : (
              <>
                <Send className="mr-2" size={20} />
                Send Message
              </>
            )}
          </button>
        </form>
        <div className="mt-8 pt-6 border-t border-gray-200">
          <h2 className="text-xl font-semibold text-[#171938] mb-4">Other Ways to Reach Us</h2>
          <div className="flex items-center text-gray-600">
            <Mail className="mr-2" size={20} />
            <span>sayhello@sweepzy.io</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;