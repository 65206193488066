import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Trophy, Medal, Star, MessageSquare, Home, PieChart, PlusCircle, Award, User, Settings, LogOut, Menu } from 'lucide-react';
import sweepzyLogoDark from './assets/navlogo.png';
import { supabase } from '../supabaseClient';

const NavBar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isCollapsed, setIsCollapsed] = useState(() => {
    try {
      const saved = localStorage.getItem('navCollapsed');
      return saved !== null ? JSON.parse(saved) : true;
    } catch (error) {
      console.error('Error parsing navCollapsed from localStorage:', error);
      return true;
    }
  });
  const [isMobileExpanded, setIsMobileExpanded] = useState(false);

  useEffect(() => {
    try {
      localStorage.setItem('navCollapsed', JSON.stringify(isCollapsed));
    } catch (error) {
      console.error('Error saving navCollapsed to localStorage:', error);
    }
  }, [isCollapsed]);

  const handleLogout = async () => {
    try {
      await supabase.auth.signOut();
      navigate('/');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const toggleCollapse = () => {
    setIsCollapsed((prev: boolean) => !prev);
    setIsMobileExpanded(false);
  };

  const toggleMobileExpand = () => {
    setIsMobileExpanded((prev: boolean) => !prev);
  };

  const navItems = [
    { icon: Home, label: 'Dashboard', to: '/dashboard' },
    { icon: PieChart, label: 'My Competitions', to: '/competitions' },
    { icon: PlusCircle, label: 'Add Competition', to: '/add-competition' },
    { icon: Trophy, label: 'Open Competitions', to: '/open-competitions' },
    { icon: Award, label: 'Leaderboard', to: '/leaderboard' },
    { icon: Medal, label: 'Achievements', to: '/achievements' },
    { icon: MessageSquare, label: 'Forums', to: '/forums' },
    { icon: User, label: 'Profile', to: '/profile' },
    { icon: Settings, label: 'Settings', to: '/settings' },
  ];

  const navClasses = `fixed left-0 top-0 h-screen flex flex-col transition-all duration-300 ease-in-out
    bg-gray-900 text-white
    ${isCollapsed && !isMobileExpanded ? 'w-16' : 'w-64'}
    ${isMobileExpanded ? 'z-50' : 'z-40'}`;

  return (
    <nav className={navClasses}>
      <div className="flex items-center justify-between h-16 px-4">
        {(!isCollapsed || isMobileExpanded) && (
          <img 
            src={sweepzyLogoDark}
            alt="Sweepzy.io" 
            className="h-8"
          />
        )}
        <button 
          onClick={isMobileExpanded ? toggleMobileExpand : toggleCollapse}
          className="text-white hover:text-gray-300 w-8 h-8 flex items-center justify-center ml-auto lg:hidden"
        >
          <Menu size={24} />
        </button>
        <button 
          onClick={toggleCollapse}
          className="text-white hover:text-gray-300 w-8 h-8 hidden lg:flex items-center justify-center ml-auto"
        >
          <Menu size={24} />
        </button>
      </div>
      <ul className="flex-grow flex flex-col items-center lg:items-stretch py-4 space-y-4">
        {navItems.map((item, index) => (
          <NavItem 
            key={index} 
            Icon={item.icon}
            label={item.label} 
            to={item.to} 
            isCollapsed={isCollapsed && !isMobileExpanded}
            isActive={location.pathname === item.to}
          />
        ))}
      </ul>
      <div className="mt-auto pb-4 flex justify-center">
        <button
          onClick={handleLogout}
          className={`p-2 rounded-full transition-colors flex items-center
            ${isCollapsed && !isMobileExpanded ? '' : 'w-full justify-start space-x-2 px-4'}
            ${location.pathname === '/logout' ? 'bg-purple-600' : 'bg-gray-700 hover:bg-purple-600'}`}
        >
          <LogOut size={24} className="text-white" />
          {(!isCollapsed || isMobileExpanded) && <span className="ml-3">Logout</span>}
        </button>
      </div>
    </nav>
  );
};

const NavItem: React.FC<{ 
  Icon: React.ElementType;
  label: string; 
  to: string; 
  isCollapsed: boolean;
  isActive: boolean;
}> = ({ Icon, label, to, isCollapsed, isActive }) => {
  return (
    <li className="w-full">
      <Link 
        to={to} 
        className={`flex items-center transition-colors
          ${isCollapsed ? 'justify-center' : 'px-4'}
          ${isActive ? 'text-white' : 'text-white hover:text-purple-600'}`}
      >
        <div className={`p-2 rounded-full ${isActive ? 'bg-purple-600' : 'bg-gray-700'}`}>
          <Icon size={24} className="text-white" />
        </div>
        {!isCollapsed && <span className="ml-3">{label}</span>}
      </Link>
    </li>
  );
};

export default NavBar;