import React from 'react';

const Stats: React.FC = () => (
  <section className="bg-[#171938] text-white py-12">
    <div className="container mx-auto px-4">
      <div className="flex flex-col md:flex-row items-center justify-between">
        <div className="w-full md:w-auto mb-6 md:mb-0 text-center md:text-left">
          <h2 className="text-2xl sm:text-3xl font-bold">Enter 5000+ monthly contests...</h2>
        </div>
        <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-8">
          <div className="text-center">
            <div className="flex items-center justify-center md:justify-start">
              <span className="text-yellow-400 text-xl sm:text-2xl mr-1">★★★★★</span>
              <span className="text-xl sm:text-2xl font-bold">4.9 / 5</span>
            </div>
            <p className="text-sm">GoogleReviews</p>
          </div>
          <div className="text-center">
            <div className="flex items-center justify-center md:justify-start">
              <span className="text-yellow-400 text-xl sm:text-2xl mr-1">★★★★★</span>
              <span className="text-xl sm:text-2xl font-bold">4.8 / 5</span>
            </div>
            <p className="text-sm">Trustpilot</p>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Stats;